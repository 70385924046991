import React, { useState, useEffect } from "react";
import axios from 'axios';

import "../styles/facebookfeed.css";

import AOS from 'aos';
import 'aos/dist/aos.css';

function FacebookFeed() {
    useEffect(() => {
        AOS.init({ once: true }); // Inicjalizacja AOS
    }, []);

    const [posts, setPosts] = useState([]);
    const [pageId, setPageId] = useState(null);

    const mdbLogo = require("../images/mb-logo.webp");
    const facebookIcon = require("../images/facebookfeed/facebook.svg").default;
    const likeloveIcon = require("../images/facebookfeed/like-love.png");
    const lovelikeicon = require("../images/facebookfeed/love-like.png");

    useEffect(() => {
        const fetchFacebookPosts = async () => {
            try {
                const response = await axios.get('/api/getFacebookPosts');
                const data = response.data;

                if (Array.isArray(data)) {
                    const pageId = data.length > 0 ? data[0].pageId : null;
                    setPosts(data);
                    setPageId(pageId);
                } else {
                    console.error("Błąd pobierania postów z API");
                    setPosts([]); // Ustawienie pustej tablicy w przypadku błędu
                }
            } catch (error) {
                console.error("Błąd pobierania postów z API");
                setPosts([]); // Ustawienie pustej tablicy w przypadku błędu
            }
        };

        fetchFacebookPosts();
    }, []);

    const openPostOnFacebook = (postId) => {
        if (pageId) {
            window.open(`https://www.facebook.com/${pageId}/posts/${postId}`, "_blank");
        } else {
            console.error("Błąd pobierania pageID z API");
        }
    };

    return (
        <div className="background-facebookfeed">
            <div className="section-facebookfeed">
                <div className="section-facebookfeed-header">
                    <h1 data-aos="fade-right" data-aos-duration="1000">Nasze ostatnie wpisy.</h1>
                </div>

                <div className="section-facebookfeed-wpisy">
                    {Array.isArray(posts) && posts.length > 0 ? (
                        posts.map((post, index) => (
                            <div key={index} className="facebookfeed-container" data-aos="flip-right" data-aos-duration="1000">
                                <img src={post.picture} alt="Facebook Post" className="post-image" />

                                <div className="publishday-and-author" data-aos="zoom-in" data-aos-duration="1000">
                                    <p className="publishdate">{post.createdTime}</p>
                                    <div className="logo-and-author">
                                        <img src={mdbLogo} alt="logo" className="author-logo" />
                                        <p className="author">Młodzi dla Biznesu</p>
                                    </div>
                                </div>

                                <p className="postmessage" data-aos="zoom-in" data-aos-duration="1000">
                                    {post.message}
                                </p>
                                <div className="reactions-readmore-container">
                                    {/* Warunkowe renderowanie reakcji */}
                                    {post.likeReactions > post.loveReactions ? (
                                        <div className="reactions">
                                            <div className="reaction-container">
                                                <img src={likeloveIcon} alt="likeloveIcon" className="reactions-fb-icon"/>
                                                <p className="reaction">{post.totalReactions}</p>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="reactions">
                                            <div className="reaction-container">
                                                <img src={lovelikeicon} alt="lovelikeIcon" className="reactions-fb-icon"/>
                                                <p className="reaction">{post.totalReactions}</p>
                                            </div>
                                        </div>
                                    )}
                                    <button data-aos="zoom-in" data-aos-duration="1000" className="readmore" onClick={() => openPostOnFacebook(post.postId)}>
                                        <img src={facebookIcon} alt="facebook icon" />
                                        <p className="button-readmore">Czytaj więcej</p>
                                    </button>
                                </div>
                            </div>
                        ))
                    ) : (
                        <p className="page">Błąd pobierania postów z API</p>
                    )}
                </div>
            </div>
        </div>
    );
}

export default FacebookFeed;