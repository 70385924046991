import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import axios from 'axios';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import "../styles/podcasts.css";

function Podcasts() {
  useEffect(() => {
    AOS.init({ once: true }); // Inicjalizacja AOS
  }, []);

  const playerImage = require("../images/podcasts/player-image.png");
  const playImage = require("../images/podcasts/play.svg").default;
  const youtubeImage = require("../images/podcasts/youtube.svg").default;
  const spotifyImage = require("../images/podcasts/spotify.svg").default;

  const [podcasts, setPodcasts] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 800);

  useEffect(() => {
    const fetchPodcasts = async () => {
      try {
        const response = await axios.get('/api/getPodcasts');

        if (Array.isArray(response.data)) {
          setPodcasts(response.data);
        } else {
          console.error('Błąd pobierania podcastów z API');
          setPodcasts([]); // Ustawienie pustej tablicy w przypadku błędu
        }
      } catch (error) {
        console.error('Błąd pobierania podcastów z API');
        setPodcasts([]); // Ustawienie pustej tablicy w przypadku błędu
      }
    };

    fetchPodcasts();

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 800);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const chunkArray = (array, chunkSize) => {
    const result = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      result.push(array.slice(i, i + chunkSize));
    }
    return result;
  };

  const groupedPodcasts = () => {
    const chunkSize = isMobile ? 1 : 3;
    const chunks = chunkArray(podcasts, chunkSize);
    return Array.isArray(chunks) ? chunks : []; // Upewnij się, że zwracasz tablicę
  };

  return (
    <div className="background-podcasts">
      <div className="section-podcasts">
        <h1 className='podcast-h1' data-aos="fade-right" data-aos-duration="1000">
          Podcasty w których braliśmy udział.
        </h1>
        <div className="podcasts-container">
          {Array.isArray(groupedPodcasts()) && groupedPodcasts().length > 1 ? (
            <Swiper
              slidesPerView={1}
              spaceBetween={30}
              pagination={{ type: 'progressbar' }}
              navigation={true}
              modules={[Pagination, Navigation]}
              className="mySwiper"
            >
              {groupedPodcasts().map((group, index) => (
                <SwiperSlide key={index}>
                  <div className="podcasts-container">
                    {Array.isArray(group) && group.map((podcast, idx) => (
                      <div key={idx} className="podcast-container" data-aos="flip-right" data-aos-duration="1000">
                        <div className="player">
                          <img src={playerImage} alt="player bg" className="player-bg" />
                          <Link target="_blank" to={podcast.url}>
                            <img src={playImage} alt="play" className="play-button" />
                          </Link>
                        </div>
                        <p data-aos="zoom-in" data-aos-duration="1000" className='podcast-title'>{podcast.title}</p>
                        <div className='podcast-author' data-aos="zoom-in" data-aos-duration="1000">
                          {podcast.platform === 'Spotify' ? <img src={spotifyImage} alt='spotify' /> : 
                           podcast.platform === 'YouTube' ? <img src={youtubeImage} alt='youtube' /> : null}
                          <p className='podcast-author'>{podcast.channelName}</p>
                        </div>
                        <p data-aos="zoom-in" data-aos-duration="1000" className='podcast-date'>{podcast.date}</p>
                      </div>
                    ))}
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          ) : (
            Array.isArray(podcasts) && podcasts.length > 0 ? (
              podcasts.map((podcast, index) => (
                <div key={index} className="podcast-container" data-aos="flip-right" data-aos-duration="1000">
                  <div className="player">
                    <img src={playerImage} alt="player bg" className="player-bg" />
                    <Link target="_blank" to={podcast.url}>
                      <img src={playImage} alt="play" className="play-button" />
                    </Link>
                  </div>
                  <p data-aos="zoom-in" data-aos-duration="1000" className='podcast-title'>{podcast.title}</p>
                  <div className='podcast-author' data-aos="zoom-in" data-aos-duration="1000">
                    {podcast.platform === 'Spotify' ? <img src={spotifyImage} alt='spotify' /> : 
                     podcast.platform === 'YouTube' ? <img src={youtubeImage} alt='youtube' /> : null}
                    <p className='podcast-author'>{podcast.channelName}</p>
                  </div>
                  <p data-aos="zoom-in" data-aos-duration="1000" className='podcast-date'>{podcast.date}</p>
                </div>
              ))
            ) : (
              <p className='page'>Błąd pobierania podcastów z API</p>
            )
          )}
        </div>
      </div>
    </div>
  );
}

export default Podcasts;