import React from "react";
import "../styles/footer.css";
import { Link } from "react-router-dom";
import { Desktop, Mobile } from "../checkers/Responsivechecker";

function Footer() {

    const mdbLogo = require("../images/mb-logo.webp");
    const facebookIcon = require("../images/facebook.svg").default;
    const tiktokIcon = require("../images/tiktok.svg").default;
    const instagramIcon = require("../images/instagram.svg").default;

    return (
        <>
            <Desktop>
            <div className="background-footer">
                <div className="section-footer">
                    <div className="logo-name-socialmedia-footer">
                        <img src={mdbLogo} alt="logo" className="logo-footer"/>
                        <div className="name-socialmedia-footer">
                            <p className="fundationname-footer">Fundacja na rzecz edukacji i rozwoju<br></br> młodego pokolenia "Młodzi dla Biznesu"</p>
                            <div className="socialmedia-footer-container">
                                <p className="head-footer">Nasze social media:</p>
                                <Link to="https://www.facebook.com/MlodzidlaBiznesu" target="_blank">
                                    <img src={facebookIcon} alt="facebook" className="socialicon"/>
                                </Link>
                                <Link to="">
                                    <img src={tiktokIcon} alt="tiktok" className="socialicon"/>
                                </Link>
                                <Link to="https://www.instagram.com/mlodzidlabiznesu/" target="_blank">
                                    <img src={instagramIcon} alt="instagram" className="socialicon"/>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="important-links-footer">
                        <p className="head-footer">Ważne linki</p>
                        <Link to="/statut">
                            <p className="importantlinks-footer">Statut fundacji</p>
                        </Link>
                        <Link to="/politykaprywatnosci">
                            <p className="importantlinks-footer">Polityka prywatności</p>
                        </Link>
                        <Link to="/regulaminplatnosci">
                            <p className="importantlinks-footer">Regulamin płatności</p>
                        </Link>
                    </div>
                </div>
            </div>
            </Desktop>
            <Mobile>
            <div className="background-footer">
                <div className="section-footer">
                    <div className="logo-name-socialmedia-footer">
                        <div className="name-socialmedia-footer">
                            <div className="footer-mobile">
                                <img src={mdbLogo} alt="logo" className="logo-footer"/>
                                <p className="fundationname-footer">Fundacja na rzecz edukacji i rozwoju młodego pokolenia <br />"Młodzi dla Biznesu"</p>
                            </div>
                        </div>
                    </div>
                    
                    <div className="socialmedia-importantlinks-mobile">
                        <div className="socialmedia-footer-container">
                            <p className="head-footer">Nasze</p>
                            <p className="head-footer" style={{marginTop: "-0.5rem"}}>socialmedia</p>
                            <div className="socialmedia-footer-container-mobile">
                                <Link to="https://www.facebook.com/MlodzidlaBiznesu" target="_blank">
                                    <img src={facebookIcon} alt="facebook" className="socialicon"/>
                                </Link>
                                <Link to="">
                                    <img src={tiktokIcon} alt="tiktok" className="socialicon"/>
                                </Link>
                                <Link to="https://www.instagram.com/mlodzidlabiznesu/" target="_blank">
                                    <img src={instagramIcon} alt="instagram" className="socialicon"/>
                                </Link>
                            </div>
                        </div>
                        <div className="important-links-footer">
                            <p className="head-footer">Ważne linki</p>
                            <Link to="/statut">
                                <p className="importantlinks-footer">Statut fundacji</p>
                            </Link>
                            <Link to="/politykaprywatnosci">
                                <p className="importantlinks-footer">Polityka prywatności</p>
                            </Link>
                            <Link to="/regulaminplatnosci">
                                <p className="importantlinks-footer">Regulamin płatności</p>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            </Mobile>
            <Desktop>
            <div className="background-copyrightfooter">
                <div className="section-copyrightfooter">
                    <p className="copyright-footer"> Wszelkie prawa zastrzeżone &copy; <span style={{color: "#B8A55B"}}><Link to="/">Młodzi dla Biznesu</Link></span></p>

                    <p className="copyright-footer">Realizacja i wykonanie <span style={{color: "#B8A55B"}}><Link to="https://wavesolutions.io" target="_blank">wavesolutions.io</Link></span></p>
                </div>
            </div>
            </Desktop>
            <Mobile>
            <div className="background-copyrightfooter">
                <div className="section-copyrightfooter">
                    <p className="copyright-footer"> Wszelkie prawa zastrzeżone <br />&copy; <span style={{color: "#B8A55B"}}><Link to="">Młodzi dla Biznesu</Link></span></p>

                    <p className="copyright-footer">Realizacja i wykonanie <span style={{color: "#B8A55B"}}><Link to="https://wavesolutions.io" target="_blank">wavesolutions.io</Link></span></p>
                </div>
            </div>
            </Mobile>
        </>
    );
}

export default Footer;