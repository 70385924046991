import React, { useEffect } from 'react';
// eslint-disable-next-line
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import NextTopLoader from 'nextjs-toploader';
import Mainpage from './Mainpage';
import ActionsExtended from './sections/ActionsExtended'; 
import { PolitykaPrywatnosci, Regulaminplatnosci, Statut } from './sections/Documents';
import { Failed, Success, E404 } from './components/Handler';
import CookiesInfo from './components/CookiesInfo';

const App = () => {
  const ScrollToTop = () => {
      const { pathname } = useLocation();
      
      useEffect(() => {
        window.scrollTo(0, 0);
      }, [pathname]);
      
      return null;
    };

  return (
    <div>
        <CookiesInfo />
        <NextTopLoader
            color="#F5C400"
            initialPosition={0.08}
            crawlSpeed={100}
            height={3}
            crawl={true}
            showSpinner={true}
            easing="ease"
            speed={200}
            shadow="0 0 10px #2299DD,0 0 5px #2299DD"
            template='<div class="bar" role="bar"><div class="peg"></div></div> 
            <div class="spinner" role="spinner"><div class="spinner-icon"></div></div>'
            zIndex={1600}
            showAtBottom={false}
        />
        <ScrollToTop />
        <Routes>
            <Route path="/" element={<Mainpage />} />
            <Route path="/naszedzialania" element={<ActionsExtended />} />
            <Route path="/statut" element={<Statut />} />
            <Route path="/regulaminplatnosci" element={<Regulaminplatnosci />} />
            <Route path="/politykaprywatnosci" element={<PolitykaPrywatnosci />} />
            <Route path="/v1/payment/success" element={<Success />} />
            <Route path="/v1/payment/failed" element={<Failed />} />
            <Route path="/*" element={<E404 />} />
        </Routes>
    </div>
  );
};

export default App;
