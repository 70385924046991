import toast from "react-hot-toast";

const baseOptions = {
  padding: '20px',
  borderRadius: '20px',
};

const successOptions = {
  style: {
    ...baseOptions,
    border: '1px solid #07831B',
    color: '#FFFFFF',
    backgroundColor: '#07831B', 
  },
  iconTheme: {
    primary: '#FFFFFF',
    secondary: '#000000',
  },
};

const errorOptions = {
  style: {
    ...baseOptions,
    border: '1px solid #ED4337',
    color: '#FFFFFF',
    backgroundColor: '#ED4337', 
  },
  iconTheme: {
    primary: '#FFFFFF',
    secondary: '#000000',
  },
};

const loadingOptions = {
  style: {
    ...baseOptions,
    border: '1px solid #00529B',
    color: '#00529B',
  },
  iconTheme: {
    primary: '#00529B',
    secondary: '#BDE5F8',
  },
};

const Pushup = {
  success: (message) => toast.success(message, successOptions),
  error: (message) => toast.error(message, errorOptions),
  loading: (message) => toast.loading(message, loadingOptions),
};

export default Pushup;
