import React, { useState, useEffect } from "react";
import Pushup from "../utils/Pushup";
import "../styles/mission.css";

import AOS from 'aos';
import 'aos/dist/aos.css';
import { stripePromise } from "../utils/Stripe";
import { Link } from "react-router-dom";

function Mission() {
  useEffect(() => {
    AOS.init({once: true}); // Inicjalizacja AOS
}, []);

  const paymentmethodsImage = require("../images/mission/pmethods.svg").default;
  const donateImage = require("../images/homepage/wesprzyjnas.svg").default;

  const amounts = [5, 10, 25, 50, 100, 200, 300, 500];
  const [selectedAmount, setSelectedAmount] = useState(null);
  const [customAmount, setCustomAmount] = useState('');
  const [showPaymentButton, setShowPaymentButton] = useState(false);
  const [isCheckboxChecked, setCheckboxChecked] = useState(false);

  const handleCheckboxChange = (event) => {
    setCheckboxChecked(event.target.checked);
  };

  const handleAmountClick = (amount) => {
    if (selectedAmount === amount) {
      setSelectedAmount(null);
      setShowPaymentButton(false);
    } else {
      setSelectedAmount(amount);
      setCustomAmount(''); 
      setShowPaymentButton(true);
    }
  };

  const handleCustomAmountChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) { 
      setCustomAmount(value);
      setSelectedAmount(null);
      setShowPaymentButton(value !== '');
    }
  };
  
  const handlePaymentClick = async () => {
    const finalAmount = customAmount !== '' ? parseFloat(customAmount) : selectedAmount;
  
    if (finalAmount === null || finalAmount === '' || isNaN(finalAmount)) {
      Pushup.error("Błąd. Wybierz kwotę lub wpisz dowolną kwotę przed zapłatą.");
      return;
    } else if (!isCheckboxChecked) {
      Pushup.error("Błąd. Zaakceptuj regulamin płatności.");
      return;
    }
    else if (finalAmount === 0) {
      Pushup.error("Błąd. Kwota nie może być równa 0")
      return;
    }
  
    Pushup.loading(`Przekierowanie do płatności ${finalAmount} PLN`);
  
    try {
      const response = await fetch('/api/create-checkout-session', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ amount: finalAmount }),
      });
  
      const { id } = await response.json();
  
      const stripe = await stripePromise;
  
      if (!stripe) {
        Pushup.error("Błąd. Problem z ładowaniem Stripe.js");
        return;
      }
  
      // Przekierowanie do płatności
      const { error } = await stripe.redirectToCheckout({ sessionId: id });
  
      if (error) {
        Pushup.error(error.message);
      }
    } catch (error) {
      Pushup.error("Błąd. Problem podczas przetwarzania płatności");
    }
  };
  

  return (
    <>
      <div className="background-mission">
        <div className="section-mission">
          <div className="section-mission-text" data-aos="fade-right" data-aos-duration="1000">
            <h3>NASZA MISJA </h3>
            <p className="page">
              Nasza organizacja powstała dla młodych ludzi, którzy szukają inspiracji do działania, czy też potrzebują ustrukturyzowania swojej wiedzy. Tworzymy społeczność ciekawych ludzi, którzy przy pomocy narzędzi biznesowo - rozwojowych przeżywają przygodę, jaką jest życie.
            </p>
          </div>
          <div className="section-mission-donate" id="wesprzyjnas" data-aos="fade-left" data-aos-duration="1000"> 
            <h3>WESPRZYJ NAS </h3>
            <p className="page">
              Podoba ci się to co robimy? Wesprzyj nas i pomóż nam się rozwijać w jeszcze szybszym tempie! 
            </p>

            <div className="section-mission-paymentinfo">
              <img src={paymentmethodsImage} alt="Dostepne metody platnosci" className="available-payments-method"/>
              <p className="grey">Wybierz kwotę</p>
            </div>

            <div className="section-mission-choose-amount" data-aos="zoom-in" data-aos-duration="1200">
                {amounts.map((amount) => (
                    <button
                    key={amount}
                    className={`choose-amount ${selectedAmount === amount ? "selected" : ""}`}
                    onClick={() => handleAmountClick(amount)}
                    >
                        <p className="donate-button">{amount} <b>PLN</b></p>
                    </button>
                ))}
            </div>
            <label className="another-amount" data-aos="zoom-in" data-aos-duration="1200">
                  <input
                    className="another-amount"
                    placeholder="Wprowadź dowolną kwotę ( w PLN )"
                    pattern="^[1-9]\d*$"
                    inputMode="numeric"
                    value={customAmount}
                    onChange={handleCustomAmountChange}
                  />
            </label>

            <div className="container-donate-showhide" style={{ display: showPaymentButton ? 'flex' : 'none' }}>
              <div className="contact-form-checkbox" style={{marginTop: "1.2rem"}}>
                <div className='contact-form-checkbox-recentagle'>
                  <input
                  type="checkbox"
                  name="agree"
                  checked={isCheckboxChecked}
                  onChange={handleCheckboxChange}
                  className="checkbox-contactform"
                  required
                  />
                </div>
                <div className='contact-form-checkbox-text'>
                  <p className='contactform-checkbox'> Przechodzę do płatności i akceptuję <Link to="/regulaminplatnosci"><span style={{fontWeight: 400, textDecorationLine: "underline"}} className="link"> regulamin płatności</span></Link></p>
                </div>
              </div>
              <button
                  className="gotopayment"
                  onClick={handlePaymentClick}
                >
                  <img src={donateImage} alt='Przekaz darowiznę i zapłać' />
                  <p className="button"> PRZEKAŻ DAROWIZNĘ {selectedAmount !== null ? `( ${selectedAmount} PLN )` : customAmount !== '' ? `( ${customAmount} PLN )` : ''}</p>
              </button>
            </div>

          </div>
        </div>
      </div>
    </>
  );
}

export default Mission;
