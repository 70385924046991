import React, { useState, useEffect } from "react";
import "../styles/invest.css";

import AOS from 'aos';
import 'aos/dist/aos.css';
import Pushup from "../utils/Pushup";
import { stripePromise } from "../utils/Stripe";
import { Link } from "react-router-dom";

function Invest() {
  useEffect(() => {
    AOS.init({once: true}); // Inicjalizacja AOS
}, []);

  const biznesLama = require("../images/invest/bizneslama.webp");
  
  const materialyedukacyjne = require("../images/invest/books.svg").default;
  const niezbednikpodroznika = require("../images/invest/bookandpencil.svg").default;
  const jedenuczestnik = require("../images/invest/staff.svg").default;

  const paymentmethodsImage = require("../images/invest/invest-payment-methods.svg").default;
  const inwestujImage = require("../images/homepage/inwestuj.svg").default;

  const [materialyValue, setMaterialyValue] = useState(0);
  const [niezbednikValue, setNiezbednikValue] = useState(0);
  const [jedenuczestnikValue, setJedenuczestnikValue] = useState(0);
  
  const handleMaterialyChange = (event) => {
    setMaterialyValue(parseInt(event.target.value, 10));
  };
  
  const handleNiezbednikChange = (event) => {
    setNiezbednikValue(parseInt(event.target.value, 10));
  };
  
  const handleJedenuczestnikChange = (event) => {
    setJedenuczestnikValue(parseInt(event.target.value, 10));
  };
  
  const suma = materialyValue + niezbednikValue + jedenuczestnikValue;


  /* PLATNOSC */
  const [imie, setImie] = useState('');
  const [email, setEmail] = useState('');
  const [isCheckedPrivacyPolicy, setIsCheckedPrivacyPolicy] = useState(false);
  const [isCheckedPaymentRegulations, setIsCheckedPaymentRegulations] = useState(false);


  const handleImieChange = (event) => {
    setImie(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleCheckboxPrivacyPolicyChange = () => {
    setIsCheckedPrivacyPolicy(!isCheckedPrivacyPolicy);
  };

  const handleCheckboxPaymentRegulationsChange = () => {
      setIsCheckedPaymentRegulations(!isCheckedPaymentRegulations);
    };

  const handleSubmit = async () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    
    if (suma !== null) {
      if (!imie.trim()) {
        Pushup.error("Błąd. Wprowadź imię i nazwisko");
        return;
      } else if (!email.trim()) {
        Pushup.error("Błąd. Wprowadź adres e-mail");
        return;
      } else if (!emailRegex.test(email.trim())) {
        Pushup.error("Błąd. Niepoprawny format adresu e-mail");
        return;
      } else if (!isCheckedPrivacyPolicy || !isCheckedPaymentRegulations) {
        Pushup.error("Błąd. Musisz zaakceptować politykę prywatności i regulamin płatności");
        return;
      } else {
        Pushup.loading(`Przekierowanie do płatności ${suma} PLN`);
        try {
          const response = await fetch('/api/create-checkout-session', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ 
              amount: suma, 
              email: email.trim(), 
              name: imie.trim() 
            }),
          });
          
          const { id } = await response.json();
          
          const stripe = await stripePromise;

          if (suma === 0) {
            Pushup.error("Błąd. Kwota nie może być równa 0")
            return;
          }
          
          if (!stripe) {
            Pushup.error("Błąd. Problem z ładowaniem Stripe.js");
            return;
          }
          
          // Przekierowanie do płatności
          const { error } = await stripe.redirectToCheckout({ sessionId: id });
          
          if (error) {
            Pushup.error(error.message);
          }
        } catch (error) {
          Pushup.error("Błąd. Problem podczas przetwarzania płatności");
        }
      }
    } else {
      Pushup.error("Błąd. Wybierz kwotę przed zapłatą");
    }
  };
    
  return (
    <>
      <div className="background-invest" id="inwestuj">
        <div className="section-invest">
          <h1 className="dark" data-aos="zoom-out" data-aos-duration="1000">Inwestuj w <span style={{ fontWeight: 500 }}>przyszłość młodych!</span></h1>

          <div className="section-invest-bizneslama">
            <h1 className="bizneslama" data-aos="fade-right" data-aos-duration="1500">"<b>Biznesowe</b> Przygody"</h1>
            <img src={biznesLama} alt="" className="bizneslama"/>
          </div>
          <div className="section-invest-containers">
            <div className="section-invest-container-left">
                <div className="section-invest-container-content">
                    <img src={materialyedukacyjne} alt='Na materiały edukacyjne' className="invest-caption-img"/>
                    <h2 className="invest" data-aos="zoom-out" data-aos-duration="1000">NA MATERIAŁY EDUKACYJNE</h2>
                    <div className='suwak-container'>
                        <input
                        type="range"
                        min="0"
                        max="30"
                        step="5"
                        value={materialyValue}
                        onChange={handleMaterialyChange}
                        className='suwak'
                        />
                        <p className='invest-button' data-aos="zoom-in" data-aos-duration="1000" style={{color: "#F5C400", fontWeight: 500}}>Kwota: <span style={{color: "#FFF", fontWeight: 700}}>{materialyValue} PLN</span></p>
                    </div>
                    <p className="investdescription" data-aos="zoom-in" data-aos-duration="1300">
                    Karteczki typu post - it, markery, flamastry, długopisy, kartki typu flipchart, wydruk case studies z których korzystają uczetnicy warsztatów 
                    </p>
                </div>
            </div>
            <div className="section-invest-container-center">
                <div className="section-invest-container-content">
                    <img src={niezbednikpodroznika} alt='Na niezbędnik podróznika' className="invest-caption-img"/>
                    <h2 className="invest" data-aos="zoom-out" data-aos-duration="1000">NA NIEZBĘDNIK PODRÓŻNIKA</h2>
                    <div className='suwak-container'>
                        <input
                        type="range"
                        min="0"
                        max="60"
                        step="5"
                        value={niezbednikValue}
                        onChange={handleNiezbednikChange}
                        className='suwak'
                        />
                        <p className='invest-button' data-aos="zoom-in" data-aos-duration="1000" style={{color: "#F5C400", fontWeight: 500}}>Kwota: <span style={{color: "#FFF", fontWeight: 700}}>{niezbednikValue} PLN</span></p>
                    </div>
                    <p className="investdescription" data-aos="zoom-in" data-aos-duration="1300">
                    Zeszyt ćwiczeń dla uczestnika warsztatów z najważniejszymi zagadnieniami i narzędziami rozwojowo-biznesowymi poruszanymi w trakcie szkolenia                    </p>
                </div>
            </div>
            <div className="section-invest-container-right">
                <div className="section-invest-container-content">
                    <img src={jedenuczestnik} alt='Na jednego uczestnika' className="invest-caption-img"/>
                    <h2 className="invest" data-aos="zoom-out" data-aos-duration="1000">NA JEDNEGO UCZESTNIKA</h2>
                    <div className='suwak-container'>
                        <input
                        type="range"
                        min="0"
                        max="120"
                        step="10"
                        value={jedenuczestnikValue}
                        onChange={handleJedenuczestnikChange}
                        className='suwak'
                        />
                        <p className='invest-button' data-aos="zoom-in" data-aos-duration="1000" style={{color: "#F5C400", fontWeight: 500}}>Kwota: <span style={{color: "#FFF", fontWeight: 700}}>{jedenuczestnikValue} PLN</span></p>
                    </div>
                    <p className="investdescription" data-aos="zoom-in" data-aos-duration="1300">
                    Dojazd trenerów, przygotowanie metoryczne, stworzenie prezentacji oraz ćwiczeń warsztatowych i przeprowadzenie 15h zajęć dla 20 młodych osób                    </p>
                </div>
            </div>
          </div>

        <div className="section-invest-form">
                

            <form className="invest-form" onSubmit={handleSubmit}>
                <label>
                <input type="text" name="Wprowadź imię i nazwisko" value={imie} onChange={handleImieChange} placeholder="Wprowadź imię i nazwisko" />
                </label>
                <label>
                <input type="text" name="Wprowadź adres e-mail" value={email} onChange={handleEmailChange} placeholder="Wprowadź adres e-mail" />
                </label>
            </form>

            <div className="section-invest-checkboxs">
                <div className="checkbox-container-invest">
                  <div className="checkbox-fixed">
                    <input
                    type="checkbox"
                    name="Przechodzę do płatności i akceptuję politykę prywatności"
                    checked={isCheckedPrivacyPolicy}
                    onChange={handleCheckboxPrivacyPolicyChange}
                    className="checkbox-input-invest"
                    />
                  </div>
                  <div className="checkbox-fixed">
                    <p className="checkbox">  Wyrażam zgodę na przetwarzanie danych osobowych i akceptuję <Link to="/politykaprywatnosci"><span style={{fontWeight: 400, textDecorationLine: "underline"}} className="linkwhite"> politykę prywatności</span></Link></p>
                  </div>
                </div>

                <div className="checkbox-container-invest">
                  <div className="checkbox-fixed">
                    <input
                    type="checkbox"
                    name="Przechodzę do płatności i akceptuję regulamin płatności"
                    checked={isCheckedPaymentRegulations}
                    onChange={handleCheckboxPaymentRegulationsChange}
                    className="checkbox-input-invest"
                    />
                  </div>
                  <div className="checkbox-fixed">
                    <p className="checkbox"> Przechodzę do płatności i akceptuję <Link to="/regulaminplatnosci"><span style={{fontWeight: 400, textDecorationLine: "underline"}} className="linkwhite">regulamin płatności</span></Link></p>
                  </div>
                </div>
            </div>
           </div>

          <div className="section-invest-sumup-container">
            <div className="sumup-amount">
                <h1 className="sumup" data-aos="zoom-out" data-aos-duration="400">Podsumowanie - <span style={{fontWeight: 400}}>{suma}</span> <span style={{fontSize: 25}}>PLN</span></h1>
            </div>
            <img src={paymentmethodsImage} alt="Metody płatności" className="invest-payments-methods" data-aos="zoom-in" data-aos-duration="500"/>
            <button className="invest-gotopayment" onClick={handleSubmit} data-aos="zoom-out" data-aos-duration="600">
                <img src={inwestujImage} alt="Inwestuj"/>
                <p className="button">INWESTUJ</p>
            </button>
          </div>

        </div>
      </div>
    </>
  );
}

export default Invest;
