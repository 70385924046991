import React, { useEffect } from "react";

import "../styles/catchus.css";
import ContactForm from "../components/Contactform";

import AOS from 'aos';
import 'aos/dist/aos.css';

function Catchus() {
    useEffect(() => {
        AOS.init({once: true}); // Inicjalizacja AOS
    }, []);

    const GoogleMaps = () => {
        return (
          <>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2488.4730812706534!2d17.1531202!3d51.4127358!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470ff5bf451e8109%3A0x38a7edc81c51ff74!2sBrzezie%2050%2C%2055-100%20Brzezie%2C%20Polska!5e0!3m2!1spl!2sus!4v1705474724308!5m2!1spl!2sus"
              className="googlemaps"
              title="googlemaps"
              width="600"
              height="450"
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              allowFullScreen=""
              style={{ border: 0, outline: 'none' }} 
              iwloc="nosa"
              z="0"
              data-aos="flip-up" data-aos-duration="1000"
            ></iframe>
          </>
        );
      };
      

    return (
        <>
            <div className="background-catchus">
                <div className="section-catchus" id="kontakt">
                    <div className="section-contactus">
                        <div className="header-contactus">
                            <h1 data-aos="fade-right" data-aos-duration="1000">Formularz kontaktowy.</h1>
                        </div>
                        <ContactForm />
                    </div>
                    <div className="section-headquarters">
                        <div className="header-headquarters">
                            <h1 data-aos="fade-left" data-aos-duration="1000">Podmiot odpowiedzialny.</h1>
                        </div>
                        <div className="data-headquarters">
                            <p data-aos="zoom-in" data-aos-duration="1000" className="catchus-nazwa">Fundacja na rzecz edukacji i rozwoju młodego pokolenia "Młodzi dla Biznesu"</p>
                            <div className="nip-krs">
                                <p data-aos="zoom-in" data-aos-duration="1000" className="catchus-data">NIP: 9151823371</p>
                                <p data-aos="zoom-in" data-aos-duration="1000" className="catchus-data">KRS: 0000576882</p>
                            </div>
                            <p data-aos="zoom-in" data-aos-duration="1000" className="catchus-data">REGON: 523375347</p>
                            <p data-aos="zoom-in" data-aos-duration="1000" className="catchus-data" style={{marginTop: "2.5rem"}}>Siedziba: Brzezie 50, 55-100 Trzebnica, Polska</p>
                            <GoogleMaps />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Catchus;