import React, { useEffect } from "react";

import "../styles/aboutus.css";

import AOS from 'aos';
import 'aos/dist/aos.css';

function Aboutus() {
    useEffect(() => {
        AOS.init({once: true}); // Inicjalizacja AOS
    }, []);

    const aboutusImageborder = require("../images/aboutus/aboutus-image-border.svg").default;
    const aboutusImage = require("../images/aboutus/aboutus-image.svg").default;

    return (
        <>
            <div className="background-aboutus">
                <div className="section-aboutus" id="onas">
                    <div className="section-aboutus-image" data-aos="flip-up" data-aos-duration="1500">
                    <img src={aboutusImageborder} className="aboutus-imgborder" alt=""/>
                        <img src={aboutusImage} className="aboutus-img" alt="Jak robić zeby zrobić?"/>
                    </div>
                    <div className="section-aboutus-text" data-aos="fade-left" data-aos-duration="1000">
                        <p className="page">
                        Jesteśmy grupą młodych entuzjastów edukacji i przedsiębiorczości, którzy pragną przekształcić szkolnictwo średnie za pomocą innowacyjnych metod nauczania. Specjalizujemy się w organizowaniu inspirujących warsztatów oraz skutecznym zarządzaniu projektami, aby zachęcić uczniów do rozwijania kreatywności i samodzielnego działania. Dążymy do tworzenia środowiska, które nie tylko wspiera edukację, ale również inspiruje do przełamywania barier i realizowania marzeń.
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Aboutus;