import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import cookiesSvg from '../images/cookies.svg';

const CookiesInfo = () => {
  const [showCookiesInfo, setShowCookiesInfo] = useState(false);

  useEffect(() => {
    const cookiesAccepted = localStorage.getItem('cookiesAccepted');
    console.log('Cookies Accepted:', cookiesAccepted); 
    if (!cookiesAccepted) {
      setShowCookiesInfo(true);
    }
  }, []);

  const acceptCookies = () => {
    localStorage.setItem('cookiesAccepted', 'true');
    setShowCookiesInfo(false);
  };

  if (!showCookiesInfo) {
    return null;
  }

  return (
    <div className='cookies-container'>
      <div className='cookies-info'>
        <img src={cookiesSvg} alt='cookies' style={{ width: '40px' }} />
        <p>Nasza strona korzysta z plików cookies, aby poprawić komfort użytkowania.</p>
      </div>
      <div className='cookies-buttons'>
        <button className="homepage-inwestuj" style={{ width: '100px' }} onClick={acceptCookies}>
          <p className='cookies'>Akceptuj</p>
        </button>
        <Link to="https://google.com">
          <button className="homepage-inwestuj" style={{ width: '165px' }}>
            <p className='cookies'>Zabierz mnie stąd!</p>
          </button>
        </Link>
      </div>
    </div>
  );
};

export default CookiesInfo;
