import React, {useState, useEffect} from "react";

import "../styles/team.css";

import AOS from 'aos';
import 'aos/dist/aos.css';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Desktop, Mobile } from "../checkers/Responsivechecker";

const people = [
    {
        id: 1,
        role: 'Zarząd',
        image: require("../images/team/blazejmielnik.webp"),
        name: 'Błażej Mielnik',
        functionTitle: 'Prezes fundacji',
        description: 'Cześć! Odkąd pamiętam zawsze lubiłem pracować z ludźmi, nawet kiedyś miałem marzenie, żeby zostać nauczycielem, jednak z perspektywy czasu wyewoluowało to w zostanie edukatorem. Mam marzenie - chciałbym zmienić system edukacji w Polsce i dać szansę młodym ludziom w przygodzie z przedsiębiorczością. Czy Mi się to uda? Czas pokaże, ale z racji, że to czytasz to wydaje mi się, że jesteśmy jako Fundacja na odpowiedniej drodze!',
        achievements: [
            'Laureat Olimpiady Biznesowej',
            'Działalność w branży eventowej',
            'Panelista na Forum Ekonomicznym w Karpaczu'
        ],
        education: [
            { school: 'Szkoła Podstawowa w Ujeźdźcu Wielkim', details: '' },
            { school: 'Liceum Ogólnokształcące w Trzebnicy', details: 'profil humanistyczny' },
            { school: 'Uniwersytet Wrocławski', details: 'zarządzanie projektami społecznymi I stopnia lic.' }
        ],
    },
    {
        id: 2,
        role: 'Zarząd',
        image: require("../images/team/tomaszmajewski.webp"),
        name: 'Tomasz Majewski',
        functionTitle: 'Wiceprezes fundacji',
        description: 'Cześć! W naszej Fundacji zajmuję się szeroko pojętymi kwestiami organizacyjnymi. Wynika to z mojego charakteru, ponieważ uwielbiam patrzeć, gdy wszystko działa jak w szwajcarkim zegarku. I choć niektórzy zarzucają mi bycie "chaotycznym" to bardzo dobrze się z tym czuję - bo gdyby nie było chaosu, to nie byłoby czego porządkować ;) Dodatkowo realizuję swoją sportową pasję sędziując mecze piłki nożnej w lokalnych rozgrywkach.',
        achievements: [
            'Sędzia asystent na poziomie IV ligi',
            'Ukończony z wyróżnieniem program mentoringowy Rebelpolis',
            'Zwycięzca Ogólnopolskiego konkursu "Praca Organiczna 2.0"'
        ],
        education: [
            { school: 'Szkoła Podstawowa w Kuźniczysku', details: '' },
            { school: 'Liceum Ogólnokształcące w Trzebnicy', details: 'profil humanistyczny' },
            { school: 'Akademia Wojsk Lądowych', details: 'logistyka I stopnia lic.' }
        ],
    },
    {
        id: 3,
        role: 'Zespół',
        image: require("../images/team/oliwiamajek.webp"),
        name: 'Oliwia Majek',
        functionTitle: 'CIRO',
        description: 'Hej! Jestem Oliwia, w naszej fundacji zajmuję się fundraisingiem i koordynowaniem projektów. To ze mną kontaktują się uczestniczy warsztatów, nauczyciele i nasi partnerzy.  Jestem w fundacji, bo uwielbiam działać z innymi i robić coś, co naprawdę ma znaczenie. Chcę się uczyć i rozwijać, jednocześnie wspierając innowacje i projektowanie przyszłości - o której marzymy!',
        achievements: [
          'Laureatka Olimpiady Zwolnieni z Teorii',
          'Liderka społeczności Zwolnieni z Teorii',
          'Specjalista ds. strategii marketingowej i nawiązywania współprac'
        ],
        education: [
            { school: 'Szkoła Podstawowa nr 1 w Krapkowicach', details: '' },
            { school: 'Liceum Ogólnokształcące w Krapkowicach', details: 'profil humanistyczny' },
            { school: 'Uniwersytet Wrocławski', details: 'zarządzanie projektami społecznymi I stopnia lic.' }
        ],
      },
      {
        id: 4,
        role: 'Zespół',
        image: require("../images/team/adriangorzka.webp"),
        name: 'Adrian Gorzka',
        functionTitle: 'Trener',
        description: 'Pasjonat psychologii, neurobiologii i rozumienia mechanizmów jakie nami rządzą. Proaktywny poszukiwacz balansu w życiu i biznesie. Od zawsze fascynowały mnie związki przyczynowo- skutkowe, przez co nie poprzestaję póki nie znajdę odpowiedzi. Praktyczną wiedzę zamieniam w proste narzędzia do wspierania moich klientów. Prywatnie miłośnik przyrody, podróży, muzyki i zwierząt.',
        achievements: [
          'Przeprowadzenie ponad 1000h warsztatów i szkoleń',
          'Przeprowadzenie ponad 600h indywidualnych sesji oraz konsultacji',
          'Pierwsza otworzona działalność gospodarcza w wieku 18 lat'
        ],
        education: [
            { school: 'Uniwersytet Ekonomiczny w Poznaniu', details: 'zarządzanie (spec. handel i marketing) I stopnia lic.' },
            { school: 'Szkoła trenerów Uniwersytetu SWPS', details: 'program dyplomowy' },
            { school: 'WSKZ', details: 'psychologia pozytywna (podyplomowe)' },
            { school: 'JBB International', details: 'Akademia Trenerów Mentalnych' },
            
        ],
      },
      {
        id: 5,
        role: 'Zespół',
        image: require("../images/team/zuzannakomarnicka.webp"),
        name: 'Zuzanna Komarnicka',
        functionTitle: 'Social media',
        description: 'Jestem studentką kierunku Media Design i marketing wizerunkowy, co idealnie współgra z moją rolą w fundacji. Dbam o nasze konta na mediach społecznościowych i chętnie dokumentuję wszystko aparatem. Uwielbiam dbać o estetykę, a w kreatywnych zadaniach sprawdzam się najlepiej.',
        achievements: [
          'Półfinał konkursu "Solve for Tomorrow"',
          'Ukończenie pierwszego roku studiów ze średnią 4.98',
        ],
        education: [
            { school: 'Szkoła Podstawowa nr 2 w Trzebnicy', details: '' },
            { school: 'Liceum Ogólnokształcące w Trzebnicy', details: 'profil turystyczno-rekreacyjny' },
            { school: 'Uniwersytet Dolnośląski', details: 'social media i marketing I stopnia lic.' }
        ],
      },
      {
        id: 6,
        role: 'Zespół',
        image: require("../images/team/igorpikulski.webp"),
        name: 'Igor Pikulski',
        functionTitle: 'Social media',
        description: 'Cześć! Moje główne zainteresowania to motoryzacja i kultura japońska w sferze anime i manga. Posiadam doświadczenie oraz umiejętności, które chce przekazywać innym, a pozyskałem je poprzez działanie w rodzinnym biznesie gastronomicznym. Jestem człowiekiem, którego marzeniem była możliwość angażowania się w projekty społeczne i co muszę przyznać, marzenie to staje się rzeczywistością każdego dnia kiedy działam na rzecz Młodych dla Biznesu.',
        achievements: [
          'Współpraca promocyjna z klubem KGHM Zagłębie Lubin',
          'Działanie w rodzinnej działalności gospodarczej'
        ],
        education: [
            { school: 'Szkoła Podstawowa nr 2 w Prochowicach', details: '' },
            { school: 'Salezjańskie Liceum Ogołnokształcące w Lubinie', details: 'profil humanistyczno-prawny' },
            { school: 'Uniwersytet Wrocławski', details: 'zarządzanie projektami społecznymi I stopnia lic.' }
        ],
      },
  ];
  
  const PersonList = () => {

    const recentagles = require("../images/team/recentagles.svg").default;

    const [selectedPersonIndex, setSelectedPersonIndex] = useState(null);
  
    const handlePersonClick = (index) => {
        if (index === selectedPersonIndex) {
            setSelectedPersonIndex(null);
        } else {
            setSelectedPersonIndex(index);
        }
    };
    
    
    const selectedPerson = selectedPersonIndex !== null ? people[selectedPersonIndex] : null; 
 
    const roles = [...new Set(people.map(person => person.role))];
  
    return (
    <>
        <Desktop>
        <div className="team-cards">
            <Swiper
                pagination={{ type: 'progressbar' }}
                navigation={true}
                modules={[Pagination, Navigation]}
                className="mySwiper"
            >
            {roles.map((role, index) => (
                <SwiperSlide key={index}>
                    <div className="role-description">
                    <h3>{role}</h3>
                    </div>
                    <div className="pick-person-slider">
                    {people.filter(person => person.role === role).map(person => (
                        <img
                        key={person.id}
                        src={person.image}
                        alt="pickpersonImage"
                        className={`person-imagesmall ${selectedPersonIndex === person.id - 1 ? 'active' : 'noactive'}`}
                        onClick={() => handlePersonClick(person.id - 1)} // odejmujemy 1, bo indexy są od zera
                        />
                    ))}
                    </div>
                </SwiperSlide>
                ))}
            </Swiper>

            <div className="pick-person-text">
                <h3 style={{marginBottom: '5.5rem', marginRight: '-4rem', textDecorationLine: 'none'}}>Wybierz osobę</h3>
                <img src={recentagles} alt="recentagles" />
            </div>
        </div>
        
        <div className={`person-moreinfo ${selectedPersonIndex !== null ? 'visible' : ''}`}>
        {selectedPerson && (
            <>
            <div className="person-image">
                <img src={selectedPerson.image} alt="pickpersonImage" className="person-image"/>
                <div className="person-name">
                <p className="team-personname-function">{selectedPerson.functionTitle}</p>
                <p className="team-personname">{selectedPerson.name}</p>
                </div>
            </div>
    
            <div className="person-description">
                <p className="persondescription" style={{ marginTop: 0, height: '192px'}}>
                {selectedPerson.description}
                </p>
    
                <div className="person-achievements">
                <h3>OSIĄGNIĘCIA</h3>
                    <div className="person-achievements-cards-container">
                        {selectedPerson.achievements.map((achievement, achIndex) => (
                        <div
                            className="person-achievements-cards"
                            key={achIndex}
                            style={
                                achievement === 'Panelista na Forum Ekonomicznym w Karpaczu' || 
                                achievement === 'Zwycięzca Ogólnopolskiego konkursu "Praca Organiczna 2.0"' || 
                                achievement === 'II etap konkursu "Sukces na Bank"' || 
                                achievement === 'Pierwsza otworzona działalność gospodarcza w wieku 18 lat' ||
                                achievement === 'Specjalista ds. strategii marketingowej i nawiązywania współprac'
                                ? { width: '210%' } : {}}
                        >
                            <p className="person-achievements-carrier">{achievement}</p>
                        </div>
                        ))}
                    </div>
                </div>
            </div>
    
            <div className="person-education">
                <h3>EDUKACJA</h3>
                <div className="person-education-container">

                    <div className="bar-container">
                        <div className="person-education-bar"></div>
                        <div className="person-education-square"></div>
                    </div>

                    <div className="person-education-text">
                    {selectedPerson.education.map((edu, index) => (
                        <div key={index}>
                            <p className="education">
                                {edu.school}
                            </p>
                            <p className="education-profile">
                                {edu.details}
                            </p>
                        </div>
                    ))}
                    </div>
                </div>
            </div>
            </>
        )}
        </div>
        </Desktop>
        
        <Mobile>
        <div className="team-cards">
            <Swiper
                pagination={{ type: 'progressbar' }}
                navigation={true}
                modules={[Pagination, Navigation]}
                className="mySwiper"
            >
            {roles.map((role, index) => {
                // Filtracja osób według roli
                const peopleInRole = people.filter(person => person.role === role);
                
                // Funkcja do dzielenia listy na grupy po 3 elementy
                const chunkArray = (arr, size) => {
                    const chunkedArr = [];
                    for (let i = 0; i < arr.length; i += size) {
                        chunkedArr.push(arr.slice(i, i + size));
                    }
                    return chunkedArr;
                };

                // Podział osób na grupy po 3
                const chunkedPeople = chunkArray(peopleInRole, 3);

                // Renderowanie slajdów
                return chunkedPeople.map((group, groupIndex) => (
                    <SwiperSlide key={`${index}-${groupIndex}`}>
                        <div className="role-description">
                            <h3>{role}</h3>
                        </div>
                        <div className="pick-person-slider" style={{gap: 0, justifyContent: "space-between"}}>
                            {group.map(person => (
                                <img
                                    key={person.id}
                                    src={person.image}
                                    alt="pickpersonImage"
                                    style={{width: '105px', height: '105px'}}
                                    className={`person-imagesmall ${selectedPersonIndex === person.id - 1 ? 'active' : 'noactive'}`}
                                    onClick={() => handlePersonClick(person.id - 1)} // odejmujemy 1, bo indexy są od zera
                                />
                            ))}
                        </div>
                    </SwiperSlide>
                    ));
                })}
            </Swiper>
        </div>
        
        <div className={`person-moreinfo ${selectedPersonIndex !== null ? 'visible' : ''}`}>
        {selectedPerson && (
            <>
            <div className="person-image" style={{marginTop: "3rem"}}>
                <div className="person-name">
                <p className="team-personname-function">{selectedPerson.functionTitle}</p>
                <p className="team-personname">{selectedPerson.name}</p>
                </div>
            </div>
    
            <div className="person-description">
                <p className="persondescription" style={{ marginTop: 0, height: '192px'}}>
                {selectedPerson.description}
                </p>
    
                <div className="person-achievements">
                <h3>OSIĄGNIĘCIA</h3>
                    <div className="person-achievements-cards-container">
                        {selectedPerson.achievements.map((achievement, achIndex) => (
                        <div
                            className="person-achievements-cards"
                            key={achIndex}
                            style={
                                achievement === 'Panelista na Forum Ekonomicznym w Karpaczu' || 
                                achievement === 'Zwycięzca Ogólnopolskiego konkursu "Praca Organiczna 2.0"' || 
                                achievement === 'Handel limitowanymi wydaniami butów na skalę europejską' || 
                                achievement === 'Pierwsza otworzona działalność gospodarcza w wieku 18 lat' ||
                                achievement === 'Specjalista ds. strategii marketingowej i nawiązywania współprac'
                                ? { width: '100%' } : {}}
                        >
                            <p className="person-achievements-carrier">{achievement}</p>
                        </div>
                        ))}
                    </div>
                </div>
            </div>
    
            <div className="person-education">
                <h3>EDUKACJA</h3>
                <div className="person-education-container">

                    <div className="bar-container">
                        <div className="person-education-bar"></div>
                        <div className="person-education-square"></div>
                    </div>

                    <div className="person-education-text">
                    {selectedPerson.education.map((edu, index) => (
                        <div key={index}>
                            <p className="education">
                                {edu.school}
                            </p>
                            <p className="education-profile">
                                {edu.details}
                            </p>
                        </div>
                    ))}
                    </div>
                </div>
            </div>
            </>
        )}
        </div>
        </Mobile>
    </>
    );
  };

function Team() {

    useEffect(() => {
        if (window.innerWidth < 800) {
            AOS.init({once: true})
        } else {
            AOS.init({once: false}); // Inicjalizacja AOS
        }
    }, []);

    return (
        <>
        <div className="background-team">
            <div className="section-team" id="zespol" data-aos="fade-right" data-aos-duration="1000">
                <div className="team-heading">
                    <h1>Nasz zespół.</h1>
                </div>

                <PersonList />
            
            </div>
        </div>

        </>
    )
}

export default Team;