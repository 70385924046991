import Footer from "./Footer";
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from "react-router-dom";
import axios from 'axios';
import "../styles/handler.css";

const happyLama = require("../images/payment/happyLama.webp");
const wtfLama = require("../images/payment/wtfLama.webp");

export function Success() {
    const [message, setMessage] = useState('Trwa przetwarzanie...');
    const [title, setTitle] = useState('Trwa przetwarzanie...');
    const [image, setImage] = useState(wtfLama);
    const location = useLocation();
    const [hasSent, setHasSent] = useState(false); 
  
    useEffect(() => {
      const sendThankYouEmail = async () => {
        const session_id = new URLSearchParams(location.search).get('session_id');
  
        if (!session_id) {
          setMessage('Brak ID sesji płatności');
          setTitle('Oops! Coś poszło nie tak');
          setImage(wtfLama);
          return;
        }
  
        if (hasSent) {
          console.log('Email already sent.');
          return;
        }
  
        try {
          const response = await axios.post('/api/verify-payment', { session_id });
  
          if (response.data.received) {
            setTitle('Dziękujemy za wpłatę!');
            setMessage('Płatność udana.');
            setImage(happyLama);
          } else {
            setMessage('Problem z potwierdzeniem');
          }
          setHasSent(true); 
        } catch (error) {
          console.error('Error sending thank you email:', error);
          setMessage('Błąd przetwarzania płatności');
        }
      };
  
      sendThankYouEmail();
    }, [location.search, hasSent]); 
  
  
    return (
        <>
            <div className="background-payment">
                <div className="section-payment">
                    <div className="section-payment-happyimage">
                        <img src={image} alt="lama" className="lamaIMG" />
                    </div>
                    <div className="section-payment-column">
                        <h1 style={{ fontWeight: 800 }}>{title}</h1>
                        <h3 style={{ color: "white" }}>{message}</h3>
                        <Link to="/">
                            <button className="homepage-inwestuj" style={{ width: '290px', marginTop: '0.7rem' }}>
                                <p className="button-homepage">Przekieruj do strony głównej</p>
                            </button>
                        </Link>
                        {/* <button className="homepage-inwestuj" style={{width: '180px', marginTop: '0.7rem'}}>
                            <p className="button-homepage">Odbierz prezent</p>
                        </button> */}
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export function Failed() {
    return (
        <>
            <div className="background-payment">
                <div className="section-payment">
                    <div className="section-payment-wtfimage">
                        <img src={wtfLama} alt="wtfLama" className="lamaIMG" />
                    </div>
                    <div className="section-payment-column">
                        <h1 style={{fontWeight: 800}}>Oops! Coś poszło nie tak</h1>
                        <h3 style={{color: "white"}}>Nieudana płatność</h3>
                        <Link to="/">
                            <button className="homepage-inwestuj" style={{width: '290px', marginTop: '0.7rem'}}>
                                <p className="button-homepage">Przekieruj do strony głównej</p>
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export function E404() {
    return (
        <>
            <div className="background-payment">
                <div className="section-payment">
                    <div className="section-payment-wtfimage">
                        <img src={wtfLama} alt="wtfLama" className="lamaIMG" />
                    </div>
                    <div className="section-payment-column">
                        <h1 style={{fontWeight: 800}}>Oops! <span style={{color: "white", fontSize: "23px"}}>Chyba się zgubiłeś</span></h1>
                        <Link to="/">
                            <button className="homepage-inwestuj" style={{width: '290px', marginTop: '0.7rem'}}>
                                <p className="button-homepage">Przekieruj do strony głównej</p>
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
