import React, { useRef, useState, useEffect } from "react";

import "../styles/dropdownmenu.css";
import { Link } from "react-router-dom";

import AOS from 'aos';
import 'aos/dist/aos.css';
import { HashLink } from "react-router-hash-link";

const Dropdownmenu = () => {
  useEffect(() => {
    if (window.innerWidth < 800) {
        AOS.init({once: true})
    } else {
        AOS.init({once: false}); // Inicjalizacja AOS
    }
}, []);

  const facebookIcon = require("../images/facebook.svg");
  const tiktokIcon = require("../images/tiktok.svg");
  const instagramIcon = require("../images/instagram.svg");

  const [open, setOpen] = useState(false);
  const [logoSrc, setLogoSrc] = useState(require("../images/homepage/menu.svg").default);
  const dropdownRef = useRef(null);

  const handleOpen = () => {
    setOpen(!open);
  };

  const handleMenu = () => {
    setOpen(false);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    // Zmiana obrazka w zależności od stanu 'open'
    setLogoSrc(open ? require("../images/homepage/close-menu.svg").default : require("../images/homepage/menu.svg").default);
  }, [open]);

  return (
    <div ref={dropdownRef}>
      <img
        src={logoSrc}
        alt="logo"
        onClick={handleOpen}
        data-aos="fade-left" 
        data-aos-duration="600"
      />
      {open && (
        <>
        <div className="mobiledropdown">
          <Link to="/">
            <button onClick={handleMenu}>Strona główna</button>
          </Link>
          <HashLink smooth to="/#wesprzyjnas">
            <button onClick={handleMenu}>Wesprzyj nas</button>
          </HashLink>
          <HashLink smooth to="/#onas">
            <button onClick={handleMenu}>O nas</button>
          </HashLink>
          <Link to="/naszedzialania">
            <button onClick={handleMenu}>Nasze działania</button>
          </Link>
          <HashLink smooth to="/#inwestuj">
            <button style={{color: "#F5C400", fontWeight: 700}} onClick={handleMenu}>INWESTUJ</button>
          </HashLink>
          <HashLink smooth to="/#zespol">
            <button onClick={handleMenu}>Nasz zespół</button>
          </HashLink>
          <HashLink smooth to="/#kontakt">
            <button onClick={handleMenu}>Kontakt</button>
          </HashLink>
          <div className="mobiledropdown-importantlinks">
              <button className="importantlink-btn">
                <Link to="/politykaprywatnosci">
                  <p className="importantlink-p">Polityka prywatności</p>
                </Link>
              </button>
              <button className="importantlink-btn">
                <Link to="/regulaminplatnosci">
                  <p className="importantlink-p">Regulamin płatności</p>
                </Link>
              </button>
              <button className="importantlink-btn">
                <Link to="/statut">
                  <p className="importantlink-p">Statut fundacji</p>
                </Link>            
              </button>
            <div className="mobiledropdown-socialmedia">
              <Link to="https://www.facebook.com/MlodzidlaBiznesu" target="_blank">
                <img src={facebookIcon.default} alt="facebook" className="m-socialmedia"/>
              </Link>
              <Link to="https://www.facebook.com/MlodzidlaBiznesu" target="_blank">
                <img src={tiktokIcon.default} alt="tiktok" className="m-socialmedia"/>
              </Link>
              <Link to="https://www.instagram.com/mlodzidlabiznesu/" target="_blank">
                <img src={instagramIcon.default} alt="instagram" className="m-socialmedia"/>
              </Link>
            </div>
          </div>
        </div>
        </>
      )}
    </div>
  );
};

export default Dropdownmenu;
