import React, { useState, useEffect } from 'react';
import Footer from "../components/Footer";
import Header from "../components/Header";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import AOS from 'aos';
import 'aos/dist/aos.css';
import "../styles/team.css";
import "../styles/actionsextented.css";
import { StatItem } from './Stats';

const recentagles = require("../images/team/recentagles.svg").default;
const uczestnicy = require("../images/stats/uczniowie.svg").default;
const godzinwarsztatowIMG = require("../images/stats/czas.svg").default;
const powiat = require("../images/stats/powiat.svg").default;
const zjazd = require("../images/stats/zjazd.svg").default;

//Projekt na start - Rawicz
const projekt_na_start_1 = require("../images/actionsextented/warsztaty/projekt-na-start/projekt_na_start_1.webp");
const projekt_na_start_2 = require("../images/actionsextented/warsztaty/projekt-na-start/projekt_na_start_2.webp");
const projekt_na_start_3 = require("../images/actionsextented/warsztaty/projekt-na-start/projekt_na_start_3.webp");
const projekt_na_start_4 = require("../images/actionsextented/warsztaty/projekt-na-start/projekt_na_start_4.webp");
const projekt_na_start_5 = require("../images/actionsextented/warsztaty/projekt-na-start/projekt_na_start_5.webp");
const projekt_na_start_6 = require("../images/actionsextented/warsztaty/projekt-na-start/projekt_na_start_6.webp");
const projekt_na_start_7 = require("../images/actionsextented/warsztaty/projekt-na-start/projekt_na_start_7.webp");
const projekt_na_start_8 = require("../images/actionsextented/warsztaty/projekt-na-start/projekt_na_start_8.webp");
const projekt_na_start_9 = require("../images/actionsextented/warsztaty/projekt-na-start/projekt_na_start_9.webp");
const projekt_na_start_10 = require("../images/actionsextented/warsztaty/projekt-na-start/projekt_na_start_10.webp");

//Programy rozwojowe

//Niewazne skad jestes, wazne, ze dzialasz

const nswzd_1 = require("../images/actionsextented/programyrozwojowe/niewazneskadjesteswaznezedzialasz/nswzd_1.webp");
const nswzd_2 = require("../images/actionsextented/programyrozwojowe/niewazneskadjesteswaznezedzialasz/nswzd_2.webp");
const nswzd_3 = require("../images/actionsextented/programyrozwojowe/niewazneskadjesteswaznezedzialasz/nswzd_3.webp");
const nswzd_4 = require("../images/actionsextented/programyrozwojowe/niewazneskadjesteswaznezedzialasz/nswzd_4.webp");
const nswzd_5 = require("../images/actionsextented/programyrozwojowe/niewazneskadjesteswaznezedzialasz/nswzd_5.webp");
const nswzd_6 = require("../images/actionsextented/programyrozwojowe/niewazneskadjesteswaznezedzialasz/nswzd_6.webp");
const nswzd_7 = require("../images/actionsextented/programyrozwojowe/niewazneskadjesteswaznezedzialasz/nswzd_7.webp");
const nswzd_8 = require("../images/actionsextented/programyrozwojowe/niewazneskadjesteswaznezedzialasz/nswzd_8.webp");
const nswzd_9 = require("../images/actionsextented/programyrozwojowe/niewazneskadjesteswaznezedzialasz/nswzd_9.webp");
const nswzd_10 = require("../images/actionsextented/programyrozwojowe/niewazneskadjesteswaznezedzialasz/nswzd_10.webp");
const nswzd_11 = require("../images/actionsextented/programyrozwojowe/niewazneskadjesteswaznezedzialasz/nswzd_11.webp");
const nswzd_12 = require("../images/actionsextented/programyrozwojowe/niewazneskadjesteswaznezedzialasz/nswzd_12.webp");
const nswzd_13 = require("../images/actionsextented/programyrozwojowe/niewazneskadjesteswaznezedzialasz/nswzd_13.webp");
const nswzd_14 = require("../images/actionsextented/programyrozwojowe/niewazneskadjesteswaznezedzialasz/nswzd_14.webp");
const nswzd_15 = require("../images/actionsextented/programyrozwojowe/niewazneskadjesteswaznezedzialasz/nswzd_15.webp");
const nswzd_16 = require("../images/actionsextented/programyrozwojowe/niewazneskadjesteswaznezedzialasz/nswzd_16.webp");
const nswzd_17 = require("../images/actionsextented/programyrozwojowe/niewazneskadjesteswaznezedzialasz/nswzd_17.webp");
const nswzd_18 = require("../images/actionsextented/programyrozwojowe/niewazneskadjesteswaznezedzialasz/nswzd_18.webp");
const nswzd_19 = require("../images/actionsextented/programyrozwojowe/niewazneskadjesteswaznezedzialasz/nswzd_19.webp");
const nswzd_20 = require("../images/actionsextented/programyrozwojowe/niewazneskadjesteswaznezedzialasz/nswzd_20.webp");
const nswzd_21 = require("../images/actionsextented/programyrozwojowe/niewazneskadjesteswaznezedzialasz/nswzd_21.webp");
const nswzd_22 = require("../images/actionsextented/programyrozwojowe/niewazneskadjesteswaznezedzialasz/nswzd_22.webp");
const nswzd_23 = require("../images/actionsextented/programyrozwojowe/niewazneskadjesteswaznezedzialasz/nswzd_23.webp");

const pracaorganiczna = require("../images/actionsextented/warsztaty/pracaorganiczna/pracaorganiczna1.webp");

const pwzn1 = require("../images/actionsextented/warsztaty/pwzn/pwzn1.webp");
const pwzn2 = require("../images/actionsextented/warsztaty/pwzn/pwzn2.webp");
const pwzn3 = require("../images/actionsextented/warsztaty/pwzn/pwzn3.webp");
const pwzn4 = require("../images/actionsextented/warsztaty/pwzn/pwzn4.webp");

const biznesoweprzygody1 = require("../images/actionsextented/warsztaty/biznesoweprzygody/biznesoweprzygody1.webp");
const biznesoweprzygody2 = require("../images/actionsextented/warsztaty/biznesoweprzygody/biznesoweprzygody2.webp");
const biznesoweprzygody3 = require("../images/actionsextented/warsztaty/biznesoweprzygody/biznesoweprzygody3.webp");

const pkb1 = require("../images/actionsextented/symulacje/pkb/pkb1.webp");
const pkb2 = require("../images/actionsextented/symulacje/pkb/pkb2.webp");
const pkb3 = require("../images/actionsextented/symulacje/pkb/pkb3.webp");
const pkb4 = require("../images/actionsextented/symulacje/pkb/pkb4.webp");
const pkb5 = require("../images/actionsextented/symulacje/pkb/pkb5.webp");
const pkb6 = require("../images/actionsextented/symulacje/pkb/pkb6.webp");
const pkb7 = require("../images/actionsextented/symulacje/pkb/pkb7.webp");
const pkb8 = require("../images/actionsextented/symulacje/pkb/pkb8.webp");
const pkb9 = require("../images/actionsextented/symulacje/pkb/pkb9.webp");
const pkb10 = require("../images/actionsextented/symulacje/pkb/pkb10.webp");
const pkb11 = require("../images/actionsextented/symulacje/pkb/pkb11.webp");
const pkb12 = require("../images/actionsextented/symulacje/pkb/pkb12.webp");
const pkb13 = require("../images/actionsextented/symulacje/pkb/pkb13.webp");
const pkb14 = require("../images/actionsextented/symulacje/pkb/pkb14.webp");
const pkb15 = require("../images/actionsextented/symulacje/pkb/pkb15.webp");
const pkb16 = require("../images/actionsextented/symulacje/pkb/pkb16.webp");
const pkb17 = require("../images/actionsextented/symulacje/pkb/pkb17.webp");
const pkb18 = require("../images/actionsextented/symulacje/pkb/pkb18.webp");
const pkb19 = require("../images/actionsextented/symulacje/pkb/pkb19.webp");
const pkb20 = require("../images/actionsextented/symulacje/pkb/pkb20.webp");
const pkb21 = require("../images/actionsextented/symulacje/pkb/pkb21.webp");
const pkb22 = require("../images/actionsextented/symulacje/pkb/pkb22.webp");
const pkb23 = require("../images/actionsextented/symulacje/pkb/pkb23.webp");
const pkb24 = require("../images/actionsextented/symulacje/pkb/pkb24.webp");
const pkb25 = require("../images/actionsextented/symulacje/pkb/pkb25.webp");

const workshopData = [
  {
    buttontext: "Praca Organiczna 2.0",
    content: [
      <img src={pracaorganiczna} alt='Slide 1' key='pracaorganiczna1' className='content-img' />,
    ],
    description: [
        <div key='pracaorganicznaDesc'>
          <p className="page" style={{fontSize: 17}}>
            <b>"Praca Organiczna 2.0"</b> to innowacyjny cykl warsztatów, który powstał jako projekt społeczny przed założeniem Fundacji Młodzi dla Biznesu. Program składał się z pięciu tematycznych spotkań, obejmujących:
            <br /><br />
            ● Techniki uczenia się.
            <br />
            ● Wyznaczanie celów i nawyki.
            <br />
            ● Oszczędzanie.
            <br />
            ● Analiza własnego projektu.
            <br />
            ● Negocjacje.
            <br /><br />
            Warsztaty były skierowane do uczniów szkół średnich i miały na celu rozwijanie umiejętności praktycznych oraz przedsiębiorczych wśród młodzieży. Organizatorem tego inspirującego ogólnopolskiego konkursu była Fundacja Zakłady Kórnickie. "Praca Organiczna 2.0" pomogła wielu młodym ludziom zdobyć cenne kompetencje, które wspierają ich rozwój osobisty i zawodowy.
          </p>
        </div>
    ],
    stats: [
        <StatItem key='statPraca1' imgSrc={uczestnicy} altText="uczestnicy" headText="Ilość uczestników" count={300} suffix="" delay={1} />,
        <StatItem key='statPraca2' imgSrc={godzinwarsztatowIMG} altText="godzin warsztatow" headText="Godzin warsztatów" count={55} suffix=" godzin" delay={1} />
    ],
    info: [
        <div key='pracaorganicznaInfo'>
            <h4>Partnerzy:</h4> <p style={{color: 'white', fontWeight: 600, margin: 0, fontSize: 18}}>Konkurs Fundacja Zakłady Kórnickie</p>
        </div>
    ]
  },
  {
    buttontext: "Biznesowe Przygody",
    content: [
      <img src={biznesoweprzygody1} alt='Slide 1' key='biznesoweprzygody1' className='content-img' />,
      <img src={biznesoweprzygody2} alt='Slide 2' key='biznesoweprzygody2' className='content-img' />,
      <img src={biznesoweprzygody3} alt='Slide 3' key='biznesoweprzygody3' className='content-img' />
    ],
    description: [
        <div key='biznesoweprzygodyDesc'>
          <p className="page" style={{fontSize: 17}}>
            <b>"Biznesowe Przygody"</b> to wszechstronny cykl warsztatów, podzielony na dwie kluczowe części: rozwojową i biznesową, zaprojektowany, aby przygotować młodzież do wyzwań współczesnego świata biznesu.
            <br /><br />
            ● <span style={{color: '#F5C400', fontFamily: 'Poppins', fontWeight: 700}}>Część rozwojowa:</span> Uczestnicy uczą się wyznaczać cele metodą <b>SMART</b>, efektywnie wykorzystywać własne zasoby metodą <b>FIES</b> oraz planować zadania z wykorzystaniem <b>Matrycy Eisenhowera</b>. Te narzędzia pomagają w budowaniu solidnych fundamentów osobistego rozwoju i efektywnego zarządzania  sobą w czasie.
            <br />
            ● <span style={{color: '#F5C400', fontFamily: 'Poppins', fontWeight: 700}}>Część biznesowa:</span> Warsztaty obejmują praktyczne narzędzia biznesowe, takie jak <b>Business Model Canvas</b> i <b>Analiza SWOT</b>. Dzięki temu uczestnicy zdobywają umiejętności potrzebne do tworzenia i analizowania modeli biznesowych oraz oceny mocnych i słabych stron projektów.
            <br /><br />
            <b>"Biznesowe Przygody"</b> to doskonała okazja dla młodych ludzi do zdobycia nie tylko teoretycznej wiedzy, ale przede wszystkim praktycznych umiejętności, które są niezbędne w świecie przedsiębiorczości.
          </p>
        </div>
    ],
    stats: [
        <StatItem key='statBiznesowe1' imgSrc={uczestnicy} altText="uczestnicy" headText="Ilość uczestników" count={60} suffix="" delay={1} />,
        <StatItem key='statBiznesowe2' imgSrc={godzinwarsztatowIMG} altText="godzin warsztatow" headText="Godzin warsztatów" count={14} suffix=" godzin" delay={1} />
    ],
    info: [
        <div key='biznesoweprzygodyInfo'>
            <h4>Sfinansowane ze środków:</h4> <p style={{color: 'white', fontWeight: 600, margin: 0, fontSize: 18}}>Fundacja Zakłady Kórnickie</p>
        </div>
    ]
  },
  {
    buttontext: "Przedsiębiorczość w życiu nastolatka",
    content: [
      <img src={pwzn1} alt='Slide 1' key='pwzn1' className='content-img' />,
      <img src={pwzn2} alt='Slide 2' key='pwzn2' className='content-img' />,
      <img src={pwzn3} alt='Slide 3' key='pwzn3' className='content-img' />,
      <img src={pwzn4} alt='Slide 4' key='pwzn4' className='content-img' />
    ],
    description: [
        <div key='przedsiebiorczoscDesc'>
          <p className="page" style={{fontSize: 17}}>
            <b>"Przedsiębiorczość w życiu nastolatka"</b> to projekt realizowany w ramach Forum Młodzieży Salwatoriańskiej, który oferuje cykl warsztatów zaprojektowanych, aby wprowadzić młodzież w świat przedsiębiorczości. Celem warsztatów jest pomoc uczestnikom w odkrywaniu ich mocnych stron i pasji takich jak prowadzenie własnej Stacji Naprawy Rowerów czy zakładanie zespołu muzycznego przez uczestników projektu.
            <br /><br />
            Podczas tych zajęć młodzi ludzie mają okazję zdobyć pierwsze doświadczenie w zakresie przedsiębiorczości, rozwijając umiejętności, które mogą być nieocenione również w roli animatorów RMS. Projekt kładzie nacisk na praktyczne zastosowanie zdobytej wiedzy, inspirując uczestników do podejmowania inicjatyw i realizacji swoich pasji w życiu codziennym. "Przedsiębiorczość w życiu nastolatka" to pierwszy krok w kierunku świadomego i aktywnego uczestnictwa w świecie zawodowym i nie tylko.
          </p>
        </div>
    ],
    stats: [
        <StatItem key='statPrzedsiebiorczosc1' imgSrc={uczestnicy} altText="uczestnicy" headText="Ilość uczestników" count={12} suffix="" delay={1} />,
        <StatItem key='statPrzedsiebiorczosc2' imgSrc={godzinwarsztatowIMG} altText="godzin warsztatow" headText="Godzin warsztatów" count={15} suffix=" godzin" delay={1} />
    ],
    info: [
        <div key='przedsiebiorczoscInfo'>
            <h4>Partnerzy:</h4> <p style={{color: 'white', fontWeight: 600, margin: 0, fontSize: 18}}>Ruch Młodzieży Salwatoriańskiej</p>
        </div>
    ]
  },
  {
    buttontext: "Projekt na start",
    content: [
      <img src={projekt_na_start_1} alt='Slide 1' key='projekt1' className='content-img' />,
      <img src={projekt_na_start_2} alt='Slide 2' key='projekt2' className='content-img' />,
      <img src={projekt_na_start_3} alt='Slide 3' key='projekt3' className='content-img' />,
      <img src={projekt_na_start_4} alt='Slide 4' key='projekt4' className='content-img' />,
      <img src={projekt_na_start_5} alt='Slide 5' key='projekt5' className='content-img' />,
      <img src={projekt_na_start_6} alt='Slide 6' key='projekt6' className='content-img' />,
      <img src={projekt_na_start_7} alt='Slide 7' key='projekt7' className='content-img' />,
      <img src={projekt_na_start_8} alt='Slide 8' key='projekt8' className='content-img' />,
      <img src={projekt_na_start_9} alt='Slide 9' key='projekt9' className='content-img' />,
      <img src={projekt_na_start_10} alt='Slide 10' key='projekt10' className='content-img' />
    ],
    description: [
        <div key='projektnaStartDesc'>
          <p className="page" style={{fontSize: 17}}>
            <b>"Projekt na start"</b> to kompleksowy program warsztatów, który wprowadził młodzież w tematykę projektów społecznych. Uczestnicy dowiedzieli się, czym jest projekt społeczny, stworzyli własną definicję tego pojęcia i poznali model empatycznego podejścia do potrzeb odbiorców. Kluczowym elementem było zdefiniowanie własnych potrzeb oraz przeprowadzenie badań w lokalnej społeczności, np. w formie wywiadu lub ankiety.
            <br />
            <span style={{color: '#F5C400', fontFamily: 'Poppins', fontWeight: 700}}>Podczas warsztatów uczestnicy:</span>
            <br />
            ● Określali cel swojego projektu zgodnie z zidentyfikowanymi potrzebami.
            <br />
            ● Sprawdzali dostępne zasoby za pomocą modelu <b>FIES</b>.
            <br />
            ● Stosowali metodę wyznaczania celów <b>SMARTER</b>.
            <br />
            ● Poznawali <b>Business Model Canvas</b> i wykorzystywali go do opisu swojego projektu.
            <br />
            ● Sprawdzali mocne i słabe strony swojego projektu w <b>analizie SWOT</b>.
            <br />
            ● Opracowywali plan na wykorzystanie potencjalnych szans i przeciwdziałanie zagrożeniom wynikającym z <b>analizy SWOT</b>.
            <br /><br />
            W ramach zadania domowego uczestnicy przygotowali szkic gotowego projektu oraz plan na wykorzystanie dostępnych zasobów. <b>"Projekt na start"</b> był doskonałą okazją dla młodych ludzi, aby zdobyć praktyczne umiejętności w zakresie planowania i realizacji projektów społecznych, jednocześnie rozwijając swoje kompetencje analityczne i strategiczne.
          </p>
        </div>
    ],
    stats: [
        <StatItem key='statPNS1' imgSrc={uczestnicy} altText="uczestnicy" headText="Ilość uczestników" count={25} suffix="" delay={1} />,
        <StatItem key='statPNS2' imgSrc={godzinwarsztatowIMG} altText="godzin warsztatow" headText="Godzin warsztatów" count={15} suffix=" godzin" delay={1} />
    ],
    info: [
        <div key='projektnaStartInfo'>
            <h4 style={{fontSize: 18}}>Sfinansowane ze środków Darczyńców</h4>
        </div>
    ]
  }
];

const programyrozwojoweData = [
  {
    buttontext: "Nieważne, skąd jesteś ważne, że działasz",
    content: [
      <img src={nswzd_1} alt='Slide 1' key='content-program1' className='content-img' />,
      <img src={nswzd_2} alt='Slide 2' key='content-program2' className='content-img' />,
      <img src={nswzd_3} alt='Slide 3' key='content-program3' className='content-img' />,
      <img src={nswzd_4} alt='Slide 4' key='content-program4' className='content-img' />,
      <img src={nswzd_5} alt='Slide 5' key='content-program5' className='content-img' />,
      <img src={nswzd_6} alt='Slide 6' key='content-program6' className='content-img' />,
      <img src={nswzd_7} alt='Slide 7' key='content-program7' className='content-img' />,
      <img src={nswzd_8} alt='Slide 8' key='content-program8' className='content-img' />,
      <img src={nswzd_9} alt='Slide 9' key='content-program9' className='content-img' />,
      <img src={nswzd_10} alt='Slide 10' key='content-program10' className='content-img' />,
      <img src={nswzd_11} alt='Slide 11' key='content-program11' className='content-img' />,
      <img src={nswzd_12} alt='Slide 12' key='content-program12' className='content-img' />,
      <img src={nswzd_13} alt='Slide 13' key='content-program13' className='content-img' />,
      <img src={nswzd_14} alt='Slide 14' key='content-program14' className='content-img' />,
      <img src={nswzd_15} alt='Slide 15' key='content-program15' className='content-img' />,
      <img src={nswzd_16} alt='Slide 16' key='content-program16' className='content-img' />,
      <img src={nswzd_17} alt='Slide 17' key='content-program17' className='content-img' />,
      <img src={nswzd_18} alt='Slide 18' key='content-program18' className='content-img' />,
      <img src={nswzd_19} alt='Slide 19' key='content-program19' className='content-img' />,
      <img src={nswzd_20} alt='Slide 20' key='content-program20' className='content-img' />,
      <img src={nswzd_21} alt='Slide 21' key='content-program21' className='content-img' />,
      <img src={nswzd_22} alt='Slide 22' key='content-program22' className='content-img' />,
      <img src={nswzd_23} alt='Slide 23' key='content-program23' className='content-img' />
    ],
    description: [
      <>
        <div key='description-program1'>
          <p className="page" style={{fontSize: 17}}>
            <b>„Nieważne skąd jesteś - ważne, że działasz!”</b> to ogólnopolski program skierowany do uczniów szkół średnich, realizowany we współpracy z Europejskim Domem Spotkań - Fundacja Nowy Staw. Program ma na celu rozwijanie umiejętności młodzieży poprzez cykliczne spotkania w tematycznych grupach: Lama Trener, Lama Marketing, Lama Koordynator oraz stacjonarne zjazdy.
            <br /><br />
            Uczestnicy programu pracują nad przygotowaniem konferencji, która będzie przeznaczona dla innych młodych ludzi. Projekt opiera się na idei „od młodych dla młodych”, umożliwiając młodzieży nie tylko zdobywanie cennych umiejętności, ale również dzielenie się swoją wiedzą i doświadczeniem. Program zapewnia wsparcie w zakresie szkoleń i praktycznych działań, umożliwiając uczestnikom aktywne zaangażowanie się w organizację i przeprowadzenie wydarzenia.
          </p>
        </div>
      </>
    ],
    stats: [
      <StatItem key='stat-program1' imgSrc={uczestnicy} altText="uczestnicy" headText="Ilość uczestników" count={25} suffix="" delay={1} />,
      <StatItem key='stat-program2' imgSrc={zjazd} altText="zjazdy" headText="Ilość zjazdów" count={3} suffix="" delay={1} />
    ],
    info: [
      <>
        <div key='info-program1'>
          <h4 style={{fontSize: 18}}>Sfinansowane ze środków Unii Europejskiej <br/>Partner: <span style={{color: 'white', fontWeight: 600, margin: 0}}>Europejski Dom Spotkań - Fundacja Nowy Staw</span></h4>
        </div>
      </>
    ]
  }
];

const symulacjeData = [
    {
      buttontext: "PKB - Powiatowe Konkursy Biznesowe",
      content: [
        <img src={pkb1} alt='Slide 1' key='pkb1' className='content-img' />,
        <img src={pkb2} alt='Slide 2' key='pkb2' className='content-img' />,
        <img src={pkb3} alt='Slide 3' key='pkb3' className='content-img' />,
        <img src={pkb4} alt='Slide 4' key='pkb4' className='content-img' />,
        <img src={pkb5} alt='Slide 5' key='pkb5' className='content-img' />,
        <img src={pkb6} alt='Slide 6' key='pkb6' className='content-img' />,
        <img src={pkb7} alt='Slide 7' key='pkb7' className='content-img' />,
        <img src={pkb8} alt='Slide 8' key='pkb8' className='content-img' />,
        <img src={pkb9} alt='Slide 9' key='pkb9' className='content-img' />,
        <img src={pkb10} alt='Slide 10' key='pkb10' className='content-img' />,
        <img src={pkb11} alt='Slide 11' key='pkb11' className='content-img' />,
        <img src={pkb12} alt='Slide 12' key='pkb12' className='content-img' />,
        <img src={pkb13} alt='Slide 13' key='pkb13' className='content-img' />,
        <img src={pkb14} alt='Slide 14' key='pkb14' className='content-img' />,
        <img src={pkb15} alt='Slide 15' key='pkb15' className='content-img' />,
        <img src={pkb16} alt='Slide 16' key='pkb16' className='content-img' />,
        <img src={pkb17} alt='Slide 17' key='pkb17' className='content-img' />,
        <img src={pkb18} alt='Slide 18' key='pkb18' className='content-img' />,
        <img src={pkb19} alt='Slide 19' key='pkb19' className='content-img' />,
        <img src={pkb20} alt='Slide 20' key='pkb20' className='content-img' />,
        <img src={pkb21} alt='Slide 21' key='pkb21' className='content-img' />,
        <img src={pkb22} alt='Slide 22' key='pkb22' className='content-img' />,
        <img src={pkb23} alt='Slide 23' key='pkb23' className='content-img' />,
        <img src={pkb24} alt='Slide 24' key='pkb24' className='content-img' />,
        <img src={pkb25} alt='Slide 25' key='pkb25' className='content-img' />
      ],
      description: [
        <>
          <div key='desc-PKB'>
            <p className="page" style={{fontSize: 17}}>
              <b>Powiatowe Konkursy Biznesowe (PKB)</b> zakończyły się, oferując młodzieży z powiatów Trzebnickiego, Wołowskiego i Oleśnickiego cenne doświadczenie w dziedzinie przedsiębiorczości. Konkurs obejmował trzy etapy: test wiedzy o przedsiębiorczości, tworzenie Business Model Canvas (BMC) oraz finałową prezentację pomysłu na biznes.
              <br /><br />
              Po zakończeniu drugiego etapu, dla finalistów zorganizowano warsztaty, które miały na celu przygotowanie ich do stworzenia finalnych pomysłów. Warsztaty obejmowały szkolenia z <b>analizy SWOT</b>, metody <b>SMARTER</b> oraz przygotowania pitch decku, co pozwoliło uczestnikom na doskonalenie umiejętności potrzebnych do opracowania skutecznych projektów.
              <br /><br />
              Finał, który odbył się 18 maja 2024 roku w inQUBE - Uniwersyteckim Inkubatorze Przedsiębiorczości we Wrocławiu, zgromadził sześć drużyn, które zaprezentowały swoje innowacyjne pomysły przed jury. Wyniki I edycji PKB to: MIDSS, Kreatywna Ekonomia oraz Human 2c. Konkurs, zorganizowany we współpracy z Młody Dolny Śląsk oraz Samorządem Doktorantów Uniwersytetu Ekonomicznego, stanowił wartościowe doświadczenie i wyzwanie dla uczestników.
            </p>
          </div>
        </>
      ],
      stats: [
        <StatItem key='statPKB1' imgSrc={uczestnicy} altText="uczestnicy" headText="Ilość uczestników" count={112} suffix="" delay={1} />,
        <StatItem key='statPKB2' imgSrc={powiat} altText="powiaty" headText="Liczba powiatów" count={3} suffix="" delay={1} />
      ],
      info: [
        <>
          <div key='info-PKB'>
            <h4 style={{fontSize: 18}}>Partnerzy:</h4> <p style={{color: 'white', fontWeight: 600, margin: 0, fontSize: 18}}>Młody Dolny Śląsk <br></br>Samorząd Doktorantów Uniwersytetu Ekonomicznego we Wrocławiu</p>
          </div>
        </>
      ]
    }
];

const Warsztaty = () => {
    useEffect(() => {
            AOS.init({once: true}); // Inicjalizacja AOS
    }, []);

    const [selectedWorkshop, setSelectedWorkshop] = useState(workshopData[0]);

    return (
        <>
        <div className="warsztaty-container">
            <div className="warsztaty-heading-container">
                <div className="warsztaty-heading">
                    <h1>Warsztaty.</h1>
                    <div className="warsztaty-buttons">
                        {workshopData.map((workshop, index) => (
                        <button
                            key={index}
                            className={`warsztaty ${selectedWorkshop.buttontext === workshop.buttontext ? 'active' : ''}`}
                            onClick={() => setSelectedWorkshop(workshop)} 
                        >
                            <p className="warsztaty">
                            {workshop.buttontext}
                            </p>
                        </button>
                        ))}
                    </div>
                </div>
                <div className="pick-person-text">
                    <h3 style={{ marginBottom: '3.5rem', marginRight: '-4rem', textDecorationLine: 'none' }}>Wybierz projekt</h3>
                    <img src={recentagles} alt="recentagles" />
                </div>
            </div>
            <div className="warsztaty-content" data-aos="flip-up" data-aos-duration="1500">
                <div className="warsztaty-slider">
                    <Swiper
                        pagination={{ type: 'progressbar' }}
                        navigation={true}
                        modules={[Pagination, Navigation]}
                        className="swiperWarsztaty"
                    >
                        {selectedWorkshop.content.map((slide, index) => (
                        <SwiperSlide key={index} className="warsztaty-slider">
                            {slide}
                        </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
                <div className="warsztaty-description">
                  {selectedWorkshop.description}
                </div>
            </div>
            <div className='stats-and-info'>
                <div className='statsW'>
                  {selectedWorkshop.stats}
                </div>
                <div className='infoW'>
                  {selectedWorkshop.info}
                </div>
            </div>
        </div>
        </>
    );
};

const Symulacje = () => {
  const [selectedSimulation, setSelectedSimulation] = useState(symulacjeData[0]);

  return (
    <>
      <div className="warsztaty-container">
        <div className="warsztaty-heading-container">
          <div className="warsztaty-heading">
            <h1>Symulacje biznesowe.</h1>
            <div className="warsztaty-buttons">
              {symulacjeData.map((simulation, index) => (
                <button
                  key={simulation.buttontext} // Use unique text as key
                  className={`warsztaty ${selectedSimulation.buttontext === simulation.buttontext ? 'active' : ''}`}
                  onClick={() => setSelectedSimulation(simulation)}
                >
                  <p className="warsztaty">
                    {simulation.buttontext}
                  </p>
                </button>
              ))}
            </div>
          </div>
          <div className="pick-person-text">
            <h3 style={{ marginBottom: '3.5rem', marginRight: '-4rem', textDecorationLine: 'none' }}>Wybierz symulację</h3>
            <img src={recentagles} alt="recentagles" />
          </div>
        </div>
        <div className="warsztaty-content" data-aos="flip-up" data-aos-duration="1500">
          <div className="warsztaty-slider">
            <Swiper
              pagination={{ type: 'progressbar' }}
              navigation={true}
              modules={[Pagination, Navigation]}
              className="swiperWarsztaty"
            >
              {selectedSimulation.content.map((slide, index) => (
                <SwiperSlide key={`slide-${index}`} className="warsztaty-slider">
                  {slide}
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <div className="warsztaty-description">
            {selectedSimulation.description.map((desc, index) => (
              <div key={`desc-${index}`}>
                {desc}
              </div>
            ))}
          </div>
        </div>
        <div className='stats-and-info'>
          <div className='statsW'>
            {selectedSimulation.stats.map((stat, index) => (
              <div key={`stat-${index}`}>
                {stat}
              </div>
            ))}
          </div>
          <div className='infoW'>
            {selectedSimulation.info.map((info, index) => (
              <div key={`info-${index}`}>
                {info}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

const ProgramyRozwojowe = () => {
  const [selectedProgram, setSelectedProgram] = useState(programyrozwojoweData[0]);

  return (
    <>
      <div className="warsztaty-container">
        <div className="warsztaty-heading-container">
          <div className="warsztaty-heading">
            <h1>Programy rozwojowe.</h1>
            <div className="warsztaty-buttons">
              {programyrozwojoweData.map((program, index) => (
                <button
                  key={program.buttontext} // Use unique text as key
                  className={`warsztaty ${selectedProgram.buttontext === program.buttontext ? 'active' : ''}`}
                  onClick={() => setSelectedProgram(program)}
                >
                  <p className="warsztaty">
                    {program.buttontext}
                  </p>
                </button>
              ))}
            </div>
          </div>
          <div className="pick-person-text">
            <h3 style={{ marginBottom: '3.5rem', marginRight: '-4rem', textDecorationLine: 'none' }}>Wybierz program</h3>
            <img src={recentagles} alt="recentagles" />
          </div>
        </div>
        <div className="warsztaty-content" data-aos="flip-up" data-aos-duration="1500">
          <div className="warsztaty-slider">
            <Swiper
              pagination={{ type: 'progressbar' }}
              navigation={true}
              modules={[Pagination, Navigation]}
              className="swiperWarsztaty"
            >
              {selectedProgram.content.map((slide, index) => (
                <SwiperSlide key={`slide-${index}`} className="warsztaty-slider">
                  {slide}
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <div className="warsztaty-description">
            {selectedProgram.description.map((desc, index) => (
              <div key={`desc-${index}`}>
                {desc}
              </div>
            ))}
          </div>
        </div>
        <div className='stats-and-info'>
          <div className='statsW'>
            {selectedProgram.stats.map((stat, index) => (
              <div key={`stat-${index}`}>
                {stat}
              </div>
            ))}
          </div>
          <div className='infoW'>
            {selectedProgram.info.map((info, index) => (
              <div key={`info-${index}`}>
                {info}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

function ActionsExtented() {
  return (
    <>
      <Header />
      <div className="background-actionsextented">
        <div className="section-actionsextented">
          <Warsztaty />
          <ProgramyRozwojowe />
          <Symulacje />
        </div>
      </div>
      <Footer />
    </>
  );
}

export default ActionsExtented;
