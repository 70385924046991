import React, { useState, useEffect } from 'react';
import '../styles/catchus.css';

import Pushup from '../utils/Pushup';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link } from 'react-router-dom';

const ContactForm = () => {

  useEffect(() => {
      AOS.init({once: true})
  }, []);

  const [formData, setFormData] = useState({
    name: '',
    company: '',
    email: '',
    phonenumber: '',
    message: '',
    agree: false,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Walidacja pól formularza

    if (!formData.name) {
      Pushup.error("Wprowadź imię i nazwisko");
      return;
    }

    if (!formData.email) {
      Pushup.error("Wprowadź adres e-mail");
      return;
    }

    if (!formData.message) {
      Pushup.error("Wprowadź wiadomość");
      return;
    }

    if (!formData.agree) {
      Pushup.error("Musisz wyrazić zgodę na przetwarzanie danych osobowych");
      return;
    }

    // Wysłanie danych do serwera
    try {
      const response = await fetch('/api/sendMessage', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      
      if (response.ok) {
        Pushup.success('Wiadomość została wysłana!');
        setFormData({
          name: '',
          company: '',
          email: '',
          phonenumber: '',
          message: '',
          agree: false,
        });
      } else {
        const errorData = await response.json();
        Pushup.error(errorData.error || 'Błąd podczas wysyłania wiadomości. Spróbuj ponownie później.');
      }
    } catch (error) {
      console.error('Błąd podczas wysyłania wiadomości:', error);
      Pushup.error('Błąd podczas wysyłania wiadomości. Spróbuj ponownie później.');
    }
  };

  return (
    <form className="contact-form" onSubmit={handleSubmit} autoComplete='on'>
      <label className='required' data-aos="zoom-in" data-aos-duration="1000">
        <div className='required-text'>
          <p className='contactform-required'>wymagane</p>
        </div>
        <input
          type="text"
          className='required'
          name="name"
          value={formData.name}
          onChange={handleChange}
          placeholder='Wprowadź imię i nazwisko'
          autoComplete='name'
        />
      </label>
      <label className='optional' data-aos="zoom-in" data-aos-duration="1000">
        <input
          type="text"
          name="company"
          value={formData.company}
          onChange={handleChange}
          placeholder='Nazwa firmy lub organizacji'
          autoComplete='organization'
        />
      </label>
      <label className='required' data-aos="zoom-in" data-aos-duration="1000">
        <div className='required-text'>
          <p className='contactform-required'>wymagane</p>
        </div>
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          placeholder='Wprowadź adres e-mail'
          autoComplete='email'
        />
      </label>
      <label className='optional' data-aos="zoom-in" data-aos-duration="1000">
        <input
          type="tel"
          name="phonenumber"
          pattern="^[0-9]{9}$"
          value={formData.phonenumber}
          onChange={handleChange}
          placeholder='Wprowadź numer telefonu'
          autoComplete='tel'
        />
      </label>
      <label className='required' data-aos="zoom-in" data-aos-duration="1000">
        <div className='required-text-textarea'>
          <p className='contactform-required'>wymagane</p>
        </div>
        <textarea
          name="message"
          placeholder='Wprowadź wiadomość'
          value={formData.message}
          onChange={handleChange}
        />
      </label>

      <div className="contact-form-checkbox" data-aos="zoom-in" data-aos-duration="1000">
        <div className='contact-form-checkbox-recentagle'>
          <input
            type="checkbox"
            name="agree"
            checked={formData.agree}
            onChange={handleChange}
            className="checkbox-contactform"
          />
        </div>
        <div className='contact-form-checkbox-text'>
          <p className='contactform-checkbox'> Wyrażam zgodę na przetwarzanie danych osobowych i akceptuję <Link to="/politykaprywatnosci"><span style={{fontWeight: 400, textDecorationLine: "underline"}} className='link'> politykę prywatności</span></Link></p>
        </div>
      </div>

      <button className="contact-form-send" type="submit" data-aos="zoom-out" data-aos-duration="1000">
        <p className='button'>Wyślij wiadomość</p>
      </button>
    </form>
  );
};

export default ContactForm;
