import React, { useEffect } from 'react';
import "../styles/stats.css";
import CountUp from 'react-countup';
import AOS from 'aos';

const ucznioweIMG = require("../images/stats/uczniowie.svg").default;
const godzinwarsztatowIMG = require("../images/stats/czas.svg").default;
const szkolyIMG = require("../images/stats/szkoly.svg").default;

export function StatItem({ imgSrc, altText, headText, count, suffix, delay }) {
    useEffect(() => {
        AOS.init({once: true})
    }, []);

    return (
        <div className="stats-container" data-aos="flip-up" data-aos-duration="1200" data-aos-delay={delay}>
            <div className="stats-icon">
                <img src={imgSrc} alt={altText} className='stats-img'/>
            </div>
            <div className="stats-text">
                <p className="stats-head">{headText}</p>
                <p className="stats-count">
                    <CountUp end={count} duration={20} />{suffix}
                </p>
            </div>
        </div>
    );
}

export function StatItemHomepage({ imgSrc, altText, headText, count, suffix, delay }) {
    useEffect(() => {
        AOS.init({once: true})
    }, []);

    return (
        <div className="stats-container-homepage" data-aos="flip-up" data-aos-duration="1200" data-aos-delay={delay}>
            <div className="stats-icon">
                <img src={imgSrc} alt={altText} className='stats-img'/>
            </div>
            <div className="stats-text">
                <p className="stats-head">{headText}</p>
                <p className="stats-count">
                    <CountUp end={count} duration={20} />{suffix}
                </p>
            </div>
        </div>
    );
}

export default function Stats() {
    return (
        <>
            <div className="background-stats">
                <div className="section-stats">
                    <StatItemHomepage imgSrc={ucznioweIMG} altText="uczniowie" headText="Uczniów" count={534} suffix="" delay={500} />
                    <StatItemHomepage imgSrc={godzinwarsztatowIMG} altText="godzin warsztatow" headText="Godzin warsztatów" count={125} suffix=" godzin" delay={1000} />
                    <StatItemHomepage imgSrc={szkolyIMG} altText="szkoly" headText="Odwiedzonych szkół" count={10} suffix=" placówek" delay={1500} />
                </div>
            </div>
        </>
    );
}
