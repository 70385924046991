import React from 'react';
import Footer from "../components/Footer";
import Header from "../components/Header";
import "../styles/statut.css";
import { Link } from 'react-router-dom';

function getFormattedDate() {
    const months = [
      'stycznia', 'lutego', 'marca', 'kwietnia', 'maja', 'czerwca',
      'lipca', 'sierpnia', 'września', 'października', 'listopada', 'grudnia'
    ];
  
    const date = new Date();
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();
  
    return `${day} ${month} ${year} r.`;
}

const DocumentSection = ({ header, date, chapters }) => (
    <div className="background-statut">
        <div className="section-statut">
            <div className="scrollable-container">
                <div className="statut-container">
                    <div className="statut-header-container">
                        <div className="statut-header-column">
                            <h1 className="statut">{header.title}</h1>
                            <h3 className="statut">
                                {header.subtitle} 
                                <span style={{color: "#F5C400", fontWeight: 700}}> {header.highlight}</span>
                            </h3>
                        </div>
                        <div className="statut-header-column">
                            <h3>Stan aktualny na dzień {date}</h3>
                        </div>
                    </div>

                    {chapters.map((chapter, index) => (
                        <div key={index} className="statut-text">
                            <div className="statut-text-center">
                                <h3>{chapter.title}</h3>
                                <h3>{chapter.subtitle}</h3>
                                <h3 style={{color: "#FFF"}}>{chapter.section}</h3>
                            </div>
                            {chapter.paragraphs.map((paragraph, idx) => (
                                <p key={idx} className="statut">{paragraph}</p>
                            ))}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    </div>
);

export const Statut = () => {
    const data = {
        header: {
            title: "Statut fundacji",
            subtitle: (
                <>
                    Fundacja na Rzecz Edukacji i Rozwoju
                    <br />
                    Młodego Pokolenia
                </>
            ),
            highlight: "„MŁODZI DLA BIZNESU’’"
        },
        date: getFormattedDate(),
        chapters: [
            {
                title: "Rozdział I",
                section: "§ 1",
                paragraphs: [
                    "Fundacja pod nazwą Fundacja na Rzecz Edukacji i Rozwoju Młodego Pokolenia „MŁODZI DLA BIZNESU’’ ustanowioną przez Błażeja Mielnika zwanym dalej „Fundatorem”. Sporządzona aktem notarialnym przez notariusza Annę Welcer Reperytorium A numer 3853/2022 w dniu 15.09.2022 (Fundacja działa na podstawie przepisów ustawy z dnia 06.04.1984 o Fundacjach Dz.U.z 1991 Nr 46 poz. 203 z późn. zm.) oraz postanowień niniejszego statutu."
                ]
            },
            {
                title: "",
                section: "§ 2",
                paragraphs: [
                    "1. Fundacja posiada osobowość prawną.",
                    "2. Siedzibą fundacji : Brzezie 50 woj. Dolnośląskie.",
                    "3. Terenem działalności Fundacji jest obszar Rzeczpospolitej Polskiej, przy czym w zakresie niezbędnym dla właściwego realizowania celów określonych w statucie Fundacja może prowadzić działalność poza granicami Rzeczypospolitej Polskiej.",
                    "4. Fundacja może tworzyć oddziały, zakłady i filie.",
                    "5. Fundacja może opierać się na pracy społecznej fundatorów oraz korzystać z pomocy wolontariuszy (wolontariusze działają w ramach Fundacji na podstawie ustawy z dnia 24 kwietnia 2003r. o działalności pożytku publicznego i o wolontariacie (Dz. U. z 2003r., Nr 96, poz. 873 ze zm.).",
                    "6. Fundacja może zatrudniać pracowników.",
                    "7. Fundacja używa pieczęci z napisem wskazującym jej nazwę.",
                    "8. Fundacja może używać wyróżniającego ją znaku graficznego.",
                    '9. Fundacja używa nazwy skróconej „Młodzi dla Biznesu”',
                    "10. Fundacja powołana na czas nieokreślony.",
                    "11. Nadzór przewidziany w ustawie z dnia 6 kwietnia 1984 r. o fundacjach sprawują minister właściwy do spraw edukacji oraz ustawowo właściwy starosta.",
                    "12. Nadzór przewidziany w ustawie z dnia 24 kwietnia 2003 r. o działalności pożytku publicznego i o wolontariacie, albo nadzór wynikający z przepisów innej ustawy, sprawują organy wskazane lub określone we właściwej ustawie.",
                    "13. Fundator nie sprawuje nadzoru zewnętrznego. Zachowuje jednak dobre prawo domagania się, aby Fundacja wykonywała cele, dla których Fundację ustanowił."

                ]
            },
            {
                title: "Rozdział II",
                subtitle: "Cele Fundacji",
                section: "§ 3",
                paragraphs: [
                    "1. Wsparcie edukacji dzieci i młodzieży w obszarze przedsiębiorczości",
                    "2. Kreowanie postaw przedsiębiorczych w społeczeństwie.",
                    "3. Propagowanie wiedzy z zakresu ekonomii tj. finansów, rynków finansowych, inwestowania oraz procesów ekonomicznych.",
                    "4. Zachęcanie młodzieży do realizacji własnych celów oraz rozwijania swoich zainteresowań.",
                    "5. Szerzenie idei wolności gospodarczej oraz uczciwej konkurencji.",
                    "6. Wsparcie procesu rozwoju kariery zawodowej wśród dzieci i młodzieży.",
                    "7. Działalność zgodna z ideami współczesnej pracy organicznej.",
                    "8. Zapoznawanie młodzieży  z procesami dotyczącymi zakładania własnej firmy oraz jej prowadzenia.",
                    '9. Wsparcie merytoryczne w rozwoju firm założonych przez młodych przedsiębiorców.',
                ]
            },
            {
                title: "",
                subtitle: "",
                section: "§ 4",
                paragraphs: [
                    "Fundacja realizuje swoje cele określone w §3 w szczególności przez:",
                    "1. Prowadzenie warsztatów dla uczniów, studentów i osób zainteresowanych na temat prowadzenia własnej działalności gospodarczej oraz rozwijania cech osoby przedsiębiorczej.",
                    "2. Organizację szkoleń dla młodzieży i osób zainteresowanych z zakresu ekonomii i podstaw psychologii behawioralnej",
                    "3. Prowadzenie własnych kont w mediach społecznościowych.",
                    "4. Organizacja i uczestnictwo w wydarzeniach skierowanych do młodzieży.",
                    "5. Współpracę z organizacjami młodzieżowymi innymi organizacjami pozarządowymi, jednostkami samorządu terytorialnego, prywatnymi przedsiębiorstwami oraz jednostkami oświatowymi.",
                    "6. Prowadzenie i organizacja lekcji (zajęć) z doradcami zawodowymi, trenerami i ekspertami.",
                    "7. Badanie opinii publicznej.",
                    '8. Tworzenie i wydawanie książek, broszur informacyjnych i czasopism zgodnych z działaniami statutowymi Fundacji.',
                    '9. Tworzenie i publikowanie materiałów multimedialnych związanych z działalnością statutową Fundacji.',
                    "10. Organizacja kolonii i wyjazdów propagujących przedsiębiorczość.",
                    "11. Inne działania, zmierzające do realizacji statutowych celów Fundacji."
                ]
            },
            {
                title: "Rozdział III",
                subtitle: "Majątek i dochody Fundacji",
                section: "§ 5",
                paragraphs: [
                    "1. Majątek Fundacji stanowi fundusz założycielski, na który składa się wkład pieniężny przekazany przez Fundatora w kwocie 1000 zł ( jeden tysiąc złotych).",
                    "2. Majątek Fundacji stanowią też środki finansowe, nieruchomości i ruchomości nabyte w toku jej działalności.",
                    "3. Dochodami Fundacji są w szczególności:",
                    "- a) darowizny, dotacje, spadki i zapisy od osób fizycznych, prawnych, krajowych i zagranicznych;",
                    "- b) subwencje osób prawnych;",
                    "- c) odsetki bankowe i inne dochody kapitałowe;",
                    "- d) dochody z majątku ruchomego i nieruchomego;",
                    "- e) dochody ze zbiórek i imprez publicznych;",
                    "- f) dotacje z budżetu państwa;",
                    "- g) dotacje z programów pomocowych Unii Europejskiej oraz jednostek samorządu terytorialnego lub organów administracji rządowej.",
                    "4. Dochody uzyskiwane z majątku Fundacji oraz ze zbiórek publicznych przeznaczone są wyłącznie na realizację celów statutowych oraz na pokrycie niezbędnych kosztów działania Fundacji.",
                    "5. Dochody z subwencji, darowizn, spadków i zapisów mogą być użyte na realizację wszystkich celów Fundacji, o ile ofiarodawcy nie postanowili inaczej.",
                    "6. W przypadku powołania Fundacji do dziedziczenia, jej Zarząd składa oświadczenie o przyjęciu spadku z dobrodziejstwem inwentarza tylko wówczas, gdy w chwili złożenia tego oświadczenia jest oczywiste, że stan czynny spadku znacznie przewyższa długi spadkowe.",
                    "7. Fundacja odpowiada za swoje zobowiązania całym swoim majątkiem.",
                    "8. Fundator nie ponosi odpowiedzialności za długi i zobowiązania Fundacji.",
                    "9. Fundacja prowadzi pełną ewidencję przychodów i rozchodów.",
                    "10. Rokiem obrachunkowym jest rok kalendarzowy.",
                    "11. Majątek i dochody Fundacji są przeznaczone na realizację celu statutowego."
                ]
            },
            {
                title: "",
                subtitle: "",
                section: "§ 6",
                paragraphs: [
                    "Fundacja nie może:",
                    '- a) udzielać pożyczek lub zabezpieczeń zobowiązań majątkiem Fundacji w stosunku do jej członków, członków organów lub pracowników oraz osób, z którymi pracownicy pozostają w związku małżeńskim albo w stosunku pokrewieństwa lub powinowactwa w linii prostej, pokrewieństwa lub powinowactwa w linii bocznej do drugiego stopnia albo są związani z tytułu przysposobienia, opieki lub kurateli, zwane w dalszych punktach Statutu „osobami bliskimi”',
                    "- b) przekazać swojego majątku na rzecz swoich członków, członków organów Fundacji lub pracowników Fundacji oraz ich osób bliskich, na zasadach innych niż w stosunku do osób trzecich, w szczególności jeżeli przekazywanie to następuje bezpłatnie lub na preferencyjnych warunkach",
                    "- c) wykorzystywać majątku Fundacji na rzecz członków Fundacji, członków organów Fundacji oraz ich osób bliskich na zasadach innych niż w stosunku do osób trzecich, chyba że to wykorzystanie bezpośrednio wynika ze statutowego celu Fundacji",
                    "- d) dokonywać zakupu towarów lub usług od podmiotów, w których uczestniczą członkowie organizacji, członkowie jej organów lub pracownicy oraz ich osób bliskich, na zasadach innych niż w stosunku do osób trzecich lub po cenach wyższych niż rynkowe."

                ]
            },
            {
                title: "Rozdział IV",
                subtitle: "Organy Fundacji",
                section: "§ 7",
                paragraphs: [
                    "1. Organami Fundacji jest Zarząd Fundacji.",
                    "2. Zarząd Fundacji liczy od 1 do 9 osób powoływanych przez Fundatora na czas nieokreślony. Fundator może zostać członkiem zarządu. Fundator może powoływać nowych członków zarządu.",
                    "3. Na czele Zarządu stoi Prezes Zarządu wybierany spośród Członków Zarządu.",
                    "4. Zarząd Fundacji kieruje jej działalnością i reprezentuje ją na zewnątrz.",
                    "5. Zarząd Fundacji może udzielać pełnomocnictw do reprezentowania Fundacji.",
                    "6. Oświadczenia woli w imieniu Zarządu składają:",
                    "- a) Prezes Zarządu jednoosobowo",
                    "- b) Dwóch wiceprezesów Zarządu łącznie.",
                    "- c) Wiceprezes Zarządu i pełnomocnik prezesa Zarządu lub wiceprezesa Zarządu łącznie.",
                    "7. Spotkania zarządu odbywają się w miarę potrzeb wynikających z działalności Fundacji. Zasady spotkań określa osobno sporządzony regulamin."

                ]
            },
            {
                title: "",
                subtitle: "",
                section: "§ 8",
                paragraphs: [
                    "Do wyłącznej kompetencji Zarządu Fundacji należy:",
                    "1. Uchwalanie programów działania Fundacji i planów jej realizacji.",
                    "2. Reprezentowanie Fundacji na zewnątrz, a w szczególności wobec organów państwowych i innych organizacji udzielanie pełnomocnictw.",
                    "3. Sprawowanie pieczy nad majątkiem Fundacji.",
                    "4. Przyjmowanie darowizn, spadków i zapisów.",
                    "5. Uchwalanie rocznych planów finansowych oraz sprawozdań rocznych z działalności Fundacji.",
                    "6. Uchwalanie regulaminu pracy Zarządu.",
                    "7. Tworzenie i likwidacja oddziałów i określanie zakresu ich działania w ramach Fundacji.",
                    "8. Podejmowaniu uchwał o przystąpieniu bądź wystąpieniu Fundacji do innej organizacji.",
                    "9. Ustalenie regulaminów wydatkowania środków Fundacji na cele statutowe.",
                    "10. Ustanawianie tytułów honorowych i innych wyróżnień oraz uchwalanie regulaminu ich przyznawania osobom i innym podmiotom zasłużonym dla celów realizowanych przez Fundację.",
                    "11. Uchwalanie instrukcji w zakresie administrowania majątkiem i działalności finansowej.",
                    "12. Decydowanie o strukturze organizacyjnej Fundacji i ustalenie liczby stałych pracowników.",
                    "13. Ustalenie podziału pracy między członkami Zarządu.",
                    "14. Mandat członka Zarządu wygasa z chwilą:",
                    "- a) pisemnego zrzeczenia się funkcji;",
                    "- b) śmierci;",
                    "- c) nieusprawiedliwionej nieobecności podczas trzech kolejnych posiedzeń;",
                    "- d) długotrwałej choroby uniemożliwiającej udział w pracach zarządu,",
                    "- e) skazania prawomocnym wyrokiem za popełnienie przestępstwa umyślnie,",
                    "- f) prowadzenia przez niego działalności niezgodnej z interesem Fundacji.",
                    "15. Członkowie Zarządu z tytułu pełnienia funkcji są wynagradzani.",
                    "16. Uchwalanie uchwał dotyczących działalności Fundacji.",
                    "17. Uchwalanie uchwał o zmianach w statucie Fundacji."
                ]
            },
            {
                title: "Rozdział V",
                subtitle: "Sponsorzy i Darczyńcy",
                section: "",
                paragraphs: [
                    "W swoich materiałach informacyjnych i reklamowych Fundacja może zamieszczać informacje o swoich dobroczyńcach i sponsorach za ich uprzednio wyrażoną pisemnie lub elektronicznie zgodą.",
                    'Osobom fizycznym i prawnym, które przekażą na jej rzecz środki finansowe lub rzeczowe może być przyznany tytuł „Opiekuna Biznes Lamy". Tytuł przyznaje Zarząd”'
                ]
            },
            {
                title: "Rozdział VI",
                subtitle: "Postanowienia końcowe",
                section: "§ 10",
                paragraphs: [
                    "Dla efektywnego realizowania swoich celów Fundacja może połączyć się z inną Fundacją."
                ]
            },
            {
                title: "",
                subtitle: "",
                section: "§ 11",
                paragraphs: [
                    "Decyzję w przedmiocie połączenia podejmuje Zarząd Fundacji w drodze jednomyślnej uchwały."
                ]
            },
            {
                title: "",
                subtitle: "",
                section: "§ 12",
                paragraphs: [
                    "Fundacja ulega likwidacji w razie osiągnięcia celów dla których była powołana, lub w razie wyczerpania jej środków finansowych lub majątku. Decyzje w sprawie likwidacji Fundacji podejmuje Zarząd Fundacji w drodze jednomyślnej uchwały. Uchwała ta nie podlega zatwierdzeniu przez Fundatora."
                ]
            },
            {
                title: "",
                subtitle: "",
                section: "§ 13",
                paragraphs: [
                    "W razie śmierci Fundatora jego kompetencje zastrzeżone w Statucie przejmuje Zarząd Fundacji, który winien działać zgodnie z przepisami prawa i Statutem ustanowionym przez Fundatora i celem Fundacji, mają przede wszystkim na względzie rozwijanie młodego pokolenia w zakresie przedsiębiorczości."
                ]
            },
            {
                title: "",
                subtitle: "",
                section: "§ 14",
                paragraphs: [
                    "Statut wchodzi w życie z dniem zarejestrowania Fundacji przez Sąd Rejonowy we Wrocławiu."
                ]
            },
        ]
    };

    return (
        <>
            <Header />
            <DocumentSection 
                header={data.header} 
                date={data.date} 
                chapters={data.chapters} 
            />
            <Footer />
        </>
    );
}

export const Regulaminplatnosci = () => {
    const data = {
        header: {
            title: "Regulamin płatności",
            subtitle: (
                <>
                    Fundacja na Rzecz Edukacji i Rozwoju
                    <br />
                    Młodego Pokolenia
                </>
            ),
            highlight: "„MŁODZI DLA BIZNESU’’"
        },
        date: getFormattedDate(),
        chapters: [
            {
                title: "I. Administrator strony",
                paragraphs: [
                    "Strona Internetowa www.mlodzidlabiznesu.com jest prowadzona przez Fundację na Rzecz Edukacji i Rozwoju Młodego Pokolenia “Młodzi dla Biznesu” (zwanej dalej „Fundacją”) z siedzibą i adresem w Brzeziu, Brzezie 50, 55-100 Trzebnica, zarejestrowaną w Rejestrze Stowarzyszeń Krajowego Rejestru Sądowego, prowadzonego przez SĄD REJONOWY DLA WROCŁAWIA FABRYCZNEJ WE WROCŁAWIU, IX WYDZIAŁ GOSPODARCZY KRAJOWEGO REJESTRU SĄDOWEGO pod numerem 0000996719, NIP 9151823371, REGON 523375347"
                ]
            },
            {
                title: "II. Darczyńca",
                paragraphs: [
                    "Darczyńcą może być osoba fizyczna posiadającą zdolności prawne lub osoba prawna lub jednostka organizacyjna nie posiadająca osobowości prawnej, dalej nazywana „Darczyńcą”."
                ]
            },
            {
                title: "III. Termin przekazywania",
                paragraphs: [
                    "1. Darowizny można przekazywać 24 godziny na dobę.",
                    "2. Fundacja nie odpowiada za przerwy w dostępie do strony internetowej www.mlodzidlabiznesu.com",
                    "3. Fundacja nie odpowiada za przerwy techiczne u operatora płatności Stripe",
                    "4. Wpłaty darowizn dokonywane za pomocą płatności online na stronie internetowej (www.mlodzidlabiznesu.com) nie podlegają zwrotom."
                ]
            },
            {
                title: "IV. Formy wpłat darowizn na rzecz Fundacji",
                paragraphs: [
                    <>
                    Wpłat darowizn na rzecz Fundacji można dokonać z użyciem jednego z poniższych sposobów płatności. Obsługa płatności online tj. przy użyciu kart płatniczych oraz poprzez przelew elektroniczny realizowana jest przez bramkę płatniczą Stripe.
                    <br />< br />
                    - a) przelewy elektroniczne realizowane są w ten sposób, że Darczyńca dokonujący wpłaty darowizny po wyborze kwoty lub jej wpisaniu, zostaje przekierowany do operatora płatności Stripe. Następnie Darczyńca otrzymuje formularz płatności z odpowiednią kwotą, tytułem przelewu i danymi odbiorcy. Po wykonaniu płatności, Darczyńca powraca do strony internetowej Fundacji. Płatność może być wykonana za pośrednictwem aktualnie akceptowalnych form płatności przez operatora płatności Stripe.
                    <br />
                    - b) karty płatnicze – płatności mogą być realizowane przy pomocy aktualnie obsługiwanych kart przez operatora płatności Stripe.
                    <br /><br />
                    Dopuszcza się wpłaty bezpośrednie na konto bankowe Fundacji na Rzecz Edukacji i Rozwoju Młodego Pokolenia “Młodzi dla Biznesu”, (Brzezie 50, 55-100 Trzebnica)
                    <br /><br />
                    BNP Paribas Bank Polska SA - nr konta bankowego: 74 1600 1462 1740 6263 2000 0001
                    </>
                ]
            },
            {
                title: "V. Przeznaczenie wpłat darowizn",
                paragraphs: [
                    <>
                    1. Wpłaty darowizn dokonywane za pomocą strony internetowej www.mlodzidlabiznesu.com, będą przeznaczane na działalność Fundacji, zgodnie ze statutem Fundacji, a w szczególności na:
                    <br /><br />
                    - a) Wsparcie edukacji  młodzieży uczęszczającej do szkół średnich  w obszarze przedsiębiorczości.
                    <br />
                    - b) Kreowanie postaw przedsiębiorczych w społeczeństwie.
                    <br />
                    - c) Propagowanie wiedzy z zakresu ekonomii tj. finansów, rynków finansowych, inwestowania oraz procesów ekonomicznych.
                    <br />
                    - d) Zachęcanie młodzieży do realizacji własnych celów oraz rozwijania swoich zainteresowań.
                    <br />
                    - e) Szerzenie idei wolności gospodarczej oraz uczciwej konkurencji.
                    <br />
                    - f) Wsparcie procesu rozwoju kariery wśród uczniów szkół średnich.
                    <br />
                    - g) Działalność zgodna z ideami współczesnej pracy organicznej.
                    <br />
                    - h) Zapoznawanie młodzieży  z procesami dotyczącymi zakładania własnej firmy oraz jej prowadzenia.
                    <br />
                    - i) Wsparcie merytoryczne w rozwoju firm założonych przez młodych przedsiębiorców.
                    </>
                ]
            },
            {
                title: "VI. Polityka bezpieczeństwa danych osobowych",
                paragraphs: [
                    <>
                    1. Zgodnie z Rozporządzeniem Parlamentu Europejskiego i Rady (UE) 2016/679 z 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (dalej jako: „RODO”) i polską ustawą o ochronie danych osobowych Fundacja zapewnia ochronę wszystkich danych osobowych, jakie zostały pozyskane w wyniku prowadzenia działalności statutowej i realizacji darowizn Darczyńcy na rzecz Fundacji w serwisie www.mlodzidlabiznesu.com
                    <br /> <br />
                    2. Korzystanie z płatności online jest równoznaczne ze zgodą na przetwarzanie dobrowolnie przekazanych danych osobowych w celu realizacji tej płatności.
                    <br /> <br />
                    3. Administratorem danych jest Fundacja. Administratorem danych w ramach usługi płatności online jest właściciel serwisu Stripe.
                    <br /> <br />
                    4. W przypadkach i na zasadach określonych w RODO oraz polskiej ustawie o ochronie danych osobowych, Darczyńca ma prawo informacji o tym, jakie dane i w jakich celach są przetwarzane; do udostępniania i przeniesienia danych; do poprawienia i usunięcia danych; do wycofania zgody w każdym momencie, zgłoszenia sprzeciwu lub żądania ograniczenia przetwarzania danych; do ludzkiej interwencji i zakwestionowania decyzji w sytuacji, kiedy system mówi „nie”.
                    <br /> <br />
                    5. Szczegółowe informacje dotyczące przetwarzania danych przez Fundację są dostępne w <Link to="/politykaprywatnosci"><span className='link'>Polityce Prywatności</span></Link>
                    </>
                ]
            },
            {
                title: "VII. Postanowienia końcowe",
                paragraphs: [
                    <>
                    1. Fundacja zastrzega sobie prawo do jednostronnej zmiany niniejszego regulaminu w każdej chwili z ważnych przyczyn.
                    <br /><br />
                    2. Nieważność jednego z postanowień regulaminu nie wpływa na ważność pozostałych jego zapisów.
                    <br /><br />
                    3. Żadne z postanowień niniejszego regulaminu nie ma na celu naruszenia praw Darczyńcy. Nie może być również w ten sposób interpretowane, gdyż w przypadku niezgodności jakiejkolwiek części regulaminu z obowiązującym prawem Fundacja deklaruje bezwzględne podporządkowanie się i stosowanie tego prawa w miejsce zakwestionowanego przepisu regulaminu.
                    <br /><br />
                    4. Aktualna wersja niniejszego regulaminu jest zawsze dostępna dla Darczyńcy oraz opublikowana na stronie internetowej www.mlodzidlabiznesu.com                  
                    <br /> <br />
                    5. W sprawach nieuregulowanych niniejszym regulaminem stosuje się odpowiednie obowiązujące przepisy prawne. Kwestie sporne rozpatruje sąd właściwy miejscowo i rzeczowo.                  
                    </>
                ]
            }
        ]
    };

    return (
        <>
            <Header />
            <DocumentSection 
                header={data.header} 
                date={data.date} 
                chapters={data.chapters} 
            />
            <Footer />
        </>
    );
}

export const PolitykaPrywatnosci = () => {
    const data = {
        header: {
            title: "Polityka prywatności",
            subtitle: (
                <>
                    Fundacja na Rzecz Edukacji i Rozwoju
                    <br />
                    Młodego Pokolenia
                </>
            ),
            highlight: "„MŁODZI DLA BIZNESU’’"
        },
        date: getFormattedDate(),
        chapters: [
            {
                title: "",
                paragraphs: [
                    "Polityka prywatności opisuje zasady przetwarzania przez nas informacji na Twój temat, w tym danych osobowych oraz ciasteczek, czyli tzw. cookies"
                ]
            },
            {
                title: "I. Informacje ogólne",
                paragraphs: [
                    <>
                    1. Niniejsza polityka dotyczy Serwisu www, funkcjonującego pod domeną: mlodzidlabiznesu.com
                    <br />< br />
                    2. Operatorem serwisu oraz Administratorem danych osobowych jest: Fundacja na Rzecz Edukacji i Rozwoju Młodego Pokolenia “Młodzi dla Biznesu” (zwanej dalej „Fundacją”) z siedzibą i adresem w Brzeziu, Brzezie 50, 55-100 Trzebnica, zarejestrowaną w Rejestrze Stowarzyszeń Krajowego Rejestru Sądowego, prowadzonego przez SĄD REJONOWY DLA WROCŁAWIA FABRYCZNEJ WE WROCŁAWIU, IX WYDZIAŁ GOSPODARCZY KRAJOWEGO REJESTRU SĄDOWEGO pod numerem 0000996719, NIP 9151823371, REGON 523375347
                    <br /><br />
                    3. Adres kontaktowy poczty elektronicznej operatora: kontakt@mlodzidlabiznesu.com                    
                    <br /><br />
                    4. Operator jest Administratorem Twoich danych osobowych w odniesieniu do danych podanych dobrowolnie w Serwisie.
                    <br /><br />
                    5. Serwis wykorzystuje dane osobowe w następujących celach:
                    <br />
                    - a) Prowadzenie newslettera
                    <br />
                    - b) Obsługa zapytań przez formularz
                    <br />
                    - c) Obsługa darowizn
                    <br /><br />
                    6. Serwis realizuje funkcje pozyskiwania informacji o użytkownikach i ich zachowaniu w następujący sposób:
                    <br />
                    - a) Poprzez dobrowolnie wprowadzone w formularzach dane, które zostają wprowadzone do systemów Operatora.
                    <br />
                    - b) Poprzez zapisywanie w urządzeniach końcowych plików cookie (tzw. „ciasteczka”).
                    </>                
                ]
            },
            {
                title: "II. Wybrane metody ochrony danych stosowane przez Operatora",
                paragraphs: [
                    <>
                    1. Miejsca logowania i wprowadzania danych osobowych są chronione w warstwie transmisji (certyfikat SSL). Dzięki temu dane osobowe i dane logowania, wprowadzone na stronie, zostają zaszyfrowane w komputerze użytkownika i mogą być odczytane jedynie na docelowym serwerze.
                    <br /><br />
                    2. Istotnym elementem ochrony danych jest regularna aktualizacja wszelkiego oprogramowania, wykorzystywanego przez Operatora do przetwarzania danych osobowych, co w szczególności oznacza regularne aktualizacje komponentów programistycznych.
                    </>
                ]
            },
            {
                title: "III. Twoje prawa i dodatkowe informacje o sposobie wykorzystania danych",
                paragraphs: [
                    <>
                    1. W niektórych sytuacjach Administrator ma prawo przekazywać Twoje dane osobowe innym odbiorcom, jeśli będzie to niezbędne do wykonania zawartej z Tobą umowy lub do zrealizowania obowiązków ciążących na Administratorze. Dotyczy to takich grup odbiorców:
                    <br />
                    - a) operatorzy płatności
                    <br />
                    - b) upoważnieni pracownicy i współpracownicy, którzy korzystają z danych w celu realizacji celu działania strony
                    <br />
                    - c) firmy, świadczące usługi marketingu na rzecz Administratora
                    <br /><br />
                    2. Twoje dane osobowe przetwarzane przez Administratora nie dłużej, niż jest to konieczne do wykonania związanych z nimi czynności określonych osobnymi przepisami (np. o prowadzeniu rachunkowości). W odniesieniu do danych marketingowych dane nie będą przetwarzane dłużej niż przez 3 lata.
                    <br /><br />
                    3. Przysługuje Ci prawo żądania od Administratora:
                    <br />
                    - a) dostępu do danych osobowych Ciebie dotyczących,
                    <br />
                    - b) ich sprostowania,
                    <br />
                    - c) usunięcia,
                    <br />
                    - d) ograniczenia przetwarzania,
                    <br />
                    - e) oraz przenoszenia danych.
                    <br /><br />
                    4. Przysługuje Ci prawo do złożenia sprzeciwu w zakresie przetwarzania wskazanego w pkt 3.2 wobec przetwarzania danych osobowych w celu wykonania prawnie uzasadnionych interesów realizowanych przez Administratora, w tym profilowania, przy czym prawo sprzeciwu nie będzie mogło być wykonane w przypadku istnienia ważnych prawnie uzasadnionych podstaw do przetwarzania, nadrzędnych wobec Ciebie interesów, praw i wolności, w szczególności ustalenia, dochodzenia lub obrony roszczeń.
                    <br /><br />
                    5. Na działania Administratora przysługuje skarga do Prezesa Urzędu Ochrony Danych Osobowych, ul. Stawki 2, 00-193 Warszawa.
                    <br /><br />
                    6. Podanie danych osobowych jest dobrowolne, lecz niezbędne do obsługi Serwisu.
                    <br /><br />
                    7. W stosunku do Ciebie mogą być podejmowane czynności polegające na zautomatyzowanym podejmowaniu decyzji, w tym profilowaniu w celu świadczenia usług w ramach zawartej umowy oraz w celu prowadzenia przez Administratora marketingu bezpośredniego.
                    <br /><br />
                    8. Dane osobowe nie są przekazywane od krajów trzecich w rozumieniu przepisów o ochronie danych osobowych. Oznacza to, że nie przesyłamy ich poza teren Unii Europejskiej.
                    </>
                ]
            },
            {
                title: "IV. Informacje w formularzach",
                paragraphs: [
                    <>
                    1. Serwis zbiera informacje podane dobrowolnie przez użytkownika, w tym dane osobowe, o ile zostaną one podane.
                    <br /><br />
                    2. Serwis może zapisać informacje o parametrach połączenia (oznaczenie czasu, adres IP).
                    <br /><br />
                    3. Serwis, w niektórych wypadkach, może zapisać informację ułatwiającą powiązanie danych w formularzu z adresem e-mail użytkownika wypełniającego formularz. W takim wypadku adres e-mail użytkownika pojawia się wewnątrz adresu url strony zawierającej formularz.
                    <br /><br />
                    4. Dane podane w formularzu są przetwarzane w celu wynikającym z funkcji konkretnego formularza, np. w celu dokonania procesu obsługi zgłoszenia serwisowego lub kontaktu handlowego, rejestracji usług itp. Każdorazowo kontekst i opis formularza w czytelny sposób informuje, do czego on służy.
                    </>
                ]
            },
            {
                title: "V. Logi administratora",
                paragraphs: [
                    <>
                    Informacje zachowaniu użytkowników w serwisie mogą podlegać logowaniu. Dane te są wykorzystywane w celu administrowania serwisem.                    
                    </>
                ]
            },
            {
                title: "VI. Techniki marketingowe",
                paragraphs: [
                    <>
                    Operator stosuje analizę statystyczną ruchu na stronie, poprzez Google Analytics (Google Inc. z siedzibą w USA). Operator nie przekazuje do operatora tej usługi danych osobowych, a jedynie zanonimizowane informacje. Usługa bazuje na wykorzystaniu ciasteczek w urządzeniu końcowym użytkownika. W zakresie informacji o preferencjach użytkownika gromadzonych przez sieć reklamową Google użytkownik może przeglądać i edytować informacje wynikające z plików cookies przy pomocy narzędzia: https://www.google.com/ads/preferences/
                    </>
                ]
            },
            {
                title: "VII. Informacje o plikach cookies",
                paragraphs: [
                    <>
                    1. Serwis korzysta z plików cookies.
                    <br /><br />
                    2. Pliki cookies (tzw. „ciasteczka”) stanowią dane informatyczne, w szczególności pliki tekstowe, które przechowywane są w urządzeniu końcowym Użytkownika Serwisu i przeznaczone są do korzystania ze stron internetowych Serwisu. Cookies zazwyczaj zawierają nazwę strony internetowej, z której pochodzą, czas przechowywania ich na urządzeniu końcowym oraz unikalny numer
                    <br /><br />
                    3. Podmiotem zamieszczającym na urządzeniu końcowym Użytkownika Serwisu pliki cookies oraz uzyskującym do nich dostęp jest operator Serwisu.
                    <br /><br />
                    4. Pliki cookies wykorzystywane są w następujących celach:
                    <br />
                    - a) realizacji celów określonych powyżej w części "Techniki marketingowe";
                    <br /><br />
                    5. W ramach Serwisu stosowane są dwa zasadnicze rodzaje plików cookies: „sesyjne” (session cookies) oraz „stałe” (persistent cookies). Cookies „sesyjne” są plikami tymczasowymi, które przechowywane są w urządzeniu końcowym Użytkownika do czasu wylogowania, opuszczenia strony internetowej lub wyłączenia oprogramowania (przeglądarki internetowej). „Stałe” pliki cookies przechowywane są w urządzeniu końcowym Użytkownika przez czas określony w parametrach plików cookies lub do czasu ich usunięcia przez Użytkownika.
                    <br /><br />
                    6. Oprogramowanie do przeglądania stron internetowych (przeglądarka internetowa) zazwyczaj domyślnie dopuszcza przechowywanie plików cookies w urządzeniu końcowym Użytkownika. Użytkownicy Serwisu mogą dokonać zmiany ustawień w tym zakresie. Przeglądarka internetowa umożliwia usunięcie plików cookies. Możliwe jest także automatyczne blokowanie plików cookies. Szczegółowe informacje na ten temat zawiera pomoc lub dokumentacja przeglądarki internetowej.
                    <br /><br />
                    7. Ograniczenia stosowania plików cookies mogą wpłynąć na niektóre funkcjonalności dostępne na stronie internetowej Serwisu.
                    <br /><br />
                    8. Pliki cookies zamieszczane w urządzeniu końcowym Użytkownika Serwisu wykorzystywane mogą być również przez współpracujące z operatorem Serwisu podmioty, w szczególności dotyczy to firm: Google (Google Inc. z siedzibą w USA), Facebook (Facebook Inc. z siedzibą w USA).
                    </>
                ]
            },
            {
                title: "VIII. Zarządzanie plikami cookies",
                paragraphs: [
                    <>
                    1. Jeśli użytkownik nie chce otrzymywać plików cookies, może zmienić ustawienia przeglądarki. Zastrzegamy, że wyłączenie obsługi plików cookies niezbędnych dla procesów uwierzytelniania, bezpieczeństwa, utrzymania preferencji użytkownika może utrudnić, a w skrajnych przypadkach może uniemożliwić korzystanie ze strony www.
                    <br /><br />
                    2. W celu zarządzania ustawienia cookies wybierz z listy poniżej przeglądarkę internetową, której używasz i postępuj zgodnie z instrukcjami:
                    <br />
                    - 1) <Link target="_blank" to="https://support.microsoft.com/pl-pl/help/10607/microsoft-edge-view-delete-browser-history"><span className='link'>Edge</span></Link>
                    <br />
                    - 2) <Link target="_blank" to="https://support.microsoft.com/pl-pl/help/278835/how-to-delete-cookie-files-in-internet-explorer"><span className='link'>Internet Explorer</span></Link>
                    <br />
                    - 3) <Link target="_blank" to="https://support.google.com/chrome/answer/95647?hl=pl"><span className='link'>Chrome</span></Link>
                    <br />
                    - 4) <Link target="_blank" to="https://support.apple.com/pl-pl/guide/safari/sfri11471/mac"><span className='link'>Safari</span></Link>
                    <br />
                    - 5) <Link target="_blank" to="https://support.mozilla.org/pl/kb/wzmocniona-ochrona-przed-sledzeniem-firefox-desktop?redirectslug=Włączanie+i+wyłączanie+obsługi+ciasteczek&redirectlocale=pl"><span className='link'>Firefox</span></Link>
                    <br />
                    - 6) <Link target="_blank" to="https://help.opera.com/pl/latest/web-preferences/#cookies"><span className='link'>Opera</span></Link>
                    <br /><br />
                    Urządzenia mobilne:
                    <br />
                    - 1) <Link target="_blank" to="https://support.google.com/chrome/answer/95647?hl=pl"><span className='link'>Android</span></Link>
                    <br />
                    - 2) <Link target="_blank" to="https://support.apple.com/pl-pl/105082"><span className='link'>Safari (iOS)</span></Link>
                    <br />
                    - 3) <Link target="_blank" to="http://www.windowsphone.com/pl-pl/how-to/wp7/web/changing-privacy-and-other-browser-settings"><span className='link'>Windows Phone</span></Link>
                    </>
                ]
            }
        ]
    };

    return (
        <>
            <Header />
            <DocumentSection 
                header={data.header} 
                date={data.date} 
                chapters={data.chapters} 
            />
            <Footer />
        </>
    );
}