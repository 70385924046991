import React, { useState, useEffect } from "react";
import "../styles/header.css";
import { Desktop, Mobile } from "../checkers/Responsivechecker";
import Dropdownmenu from "./Dropdownmenu";

import { HashLink } from 'react-router-hash-link';

import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link } from "react-router-dom";

    function Header() {
        useEffect(() => {
            if (window.innerWidth < 800) {
                AOS.init({once: true})
            } else {
                AOS.init({once: false}); // Inicjalizacja AOS
            }
        }, []);

        const mdbLogo = require("../images/mb-logo.webp");
        const expandIcon = require("../images/expand.svg").default;
        
        const facebookIcon = require("../images/facebook.svg").default;
        const tiktokIcon = require("../images/tiktok.svg").default;
        const instagramIcon = require("../images/instagram.svg").default;

        const [isExpandVisible, setExpandVisible] = useState(false);

        const handleExpandEnter = () => {
            setExpandVisible(true);
        };

        const handleExpandLeave = () => {
            setExpandVisible(false);
        };

        return (
            <>
            <Desktop>
                <div className="background-header">
                    <div className="section-header">
                        <div className="column-logo-header" data-aos="fade-right" data-aos-duration="600">
                            <Link to="/">
                                <img src={mdbLogo} alt="mdb logo" className="logo-header" />
                            </Link>
                        </div>
                        <div className="column-navbar-header" data-aos="zoom-in" data-aos-duration="500">
                            <ul>
                            <Link to="/">
                                <li>Strona główna</li>
                            </Link>
                            <li>
                                <HashLink smooth to="/#wesprzyjnas">Wesprzyj nas</HashLink>
                            </li>
                            <li>
                                <HashLink smooth to="/#onas">O nas</HashLink>
                            </li>
                            <li>
                                <HashLink smooth to="/#zespol">Nasz zespół</HashLink>
                            </li>
                            <li className="invest">
                                <HashLink smooth to="/#inwestuj">INWESTUJ</HashLink>
                            </li>
                            <Link to="/naszedzialania">
                                <li>Nasze działania</li>
                            </Link>
                            <li>
                                <HashLink smooth to="/#kontakt">Kontakt</HashLink>
                            </li>
                            <li
                                className={`expand ${isExpandVisible ? "active" : ""}`}
                                onMouseEnter={handleExpandEnter}
                                onMouseLeave={handleExpandLeave}
                            >
                                Ważne linki
                                <img
                                src={expandIcon}
                                alt="expand"
                                className="expandIcon"
                                />
                                {isExpandVisible && (
                                <div className="expand-menu">
                                    <Link to="/statut">
                                        <p>Statut fundacji</p>
                                    </Link>
                                    <Link to="/regulaminplatnosci">
                                        <p>Regulamin płatnośći</p>
                                    </Link>
                                    <Link to="/politykaprywatnosci">
                                        <p>Polityka prywatności</p>
                                    </Link>
                                </div>
                                )}
                            </li>
                            </ul>
                        </div>
                        <div className="column-socialmedia-header" data-aos="fade-left" data-aos-duration="600">
                            <Link to="https://www.facebook.com/MlodzidlaBiznesu" target="_blank">
                                <img src={facebookIcon} alt="facebook" className="socialmedia-icon-header"/>
                            </Link>
                            <Link to="">
                                <img src={tiktokIcon} alt="tiktok" className="socialmedia-icon-header"/>
                            </Link>
                            <Link to="https://www.instagram.com/mlodzidlabiznesu/" target="_blank">
                                <img src={instagramIcon} alt="instagram" className="socialmedia-icon-header"/>
                            </Link>
                        </div>
                    </div>
                </div>
            </Desktop>
            <Mobile>
                <div className="background-header">
                    <div className="section-header">
                        <div className="column-logo-header" data-aos="fade-right" data-aos-duration="600">
                            <Link to="/">
                                <img src={mdbLogo} alt="mdb logo" className="logo-header" />
                            </Link>
                        </div>
                        <div className="column-navbar-header">
                            <Dropdownmenu />
                        </div>
                    </div>
                </div>
            </Mobile>
            </>
        );
        }

    export default Header;
