import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';

import reportWebVitals from './reportWebVitals';
import App from './App'; 
import { Toaster } from 'react-hot-toast';

createRoot(document.getElementById('_react')).render(
  <Router>
    <App />
    <Toaster
      position="bottom-center"
      reverseOrder={false}
    />
  </Router>
);

reportWebVitals();
