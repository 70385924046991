import React, { useEffect } from "react";

import "../styles/actions.css";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link } from "react-router-dom";

function Actions() {
    useEffect(() => {
        AOS.init({once: true}); // Inicjalizacja AOS
    }, []);

    return (
        <>
            <div className="background-actions">
                <div className="section-actions">
                    <h1 data-aos="fade-right" data-aos-duration="1000">Nasze działania</h1>
                    <Link to="/naszedzialania">
                        <button className="redirect-actions" data-aos="fade-left" data-aos-duration="1000">
                        <p className="button">Zobacz nasze działania</p>
                        </button>
                    </Link>
                </div>
            </div>
        </>
    );
}

export default Actions;