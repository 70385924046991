import React from 'react';
import Homepage from './sections/Homepage';
import Header from './components/Header';

import './styles/index.css';
import './fonts/Poppins/fontstylepoppins.css';
import './fonts/Roboto/fontstyleroboto.css';
import Mission from './sections/Mission';
import Aboutus from './sections/Aboutus';
import Actions from './sections/Actions';
import Invest from './sections/Invest';
import FacebookFeed from './sections/FacebookFeed';
import Podcasts from './sections/Podcasts';
import Catchus from './sections/Catchus';
import Footer from './components/Footer';
import Team from './sections/Team';
import Stats from './sections/Stats';
import { Elements } from '@stripe/react-stripe-js';
import { stripePromise } from './utils/Stripe';

function Mainpage() {
  return (
      <>
        <Header />
        <Homepage />
        <Stats />
        <Elements stripe={stripePromise}>
          <Mission />
        </Elements>
        <Aboutus />
        <Team />
        <Elements stripe={stripePromise}>
          <Invest />
        </Elements>
        <Actions />
        <FacebookFeed />
        <Podcasts />
        <Catchus />
        <Footer />
      </>
  );
}

export default Mainpage;