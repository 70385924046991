import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

import "../styles/homepage.css";
import { Desktop, Mobile } from '../checkers/Responsivechecker';
import { HashLink } from 'react-router-hash-link';

function Homepage() {
    useEffect(() => {
        AOS.init({once: true}); // Inicjalizacja AOS
    }, []);

    const sectionImageborder = require("../images/homepage/homepage-image-border.svg").default;
    const sectionImage = require("../images/homepage/homepage-image.webp");
    const wesprzyjnasImage = require("../images/homepage/wesprzyjnas.svg").default;
    const inwestujImage = require("../images/homepage/inwestuj.svg").default;

    return (
        <>

            <div className="background-homepage">
                <div className="section-homepage">
                    <div className="section-homepage-text">
                        <Desktop>
                            <h1 className="homepage" data-aos="fade-up" data-aos-duration="1000">Nieważne skąd jesteś, ważne, <br />że działasz!</h1>
                        </Desktop>
                        <Mobile>
                            <h1 className="homepage" data-aos="fade-up" data-aos-duration="1000">Nieważne skąd jesteś, ważne, że działasz!</h1>
                        </Mobile>
                        <p className="homepage" data-aos="fade-up" data-aos-duration="1000">
                        Jeżeli masz cel, dąż do niego. Możesz osiągnąć wszystko. 
                        Sky is the limit.
                        </p>

                        <div className="section-homepage-buttons">
                            <button className="homepage-wesprzyjnas" data-aos="fade-up" data-aos-duration="1200">
                                <HashLink smooth to="/#wesprzyjnas" style={{display: "flex"}}> 
                                    <img src={wesprzyjnasImage} alt="Wesprzyj nas"/>
                                    <p className="button-homepage">WESPRZYJ NAS</p>
                                </HashLink>
                            </button>
                            <button className="homepage-inwestuj" data-aos="fade-up" data-aos-duration="1200">
                                <HashLink smooth to="/#inwestuj" style={{display: "flex", gap: "0.2rem"}}> 
                                    <img src={inwestujImage} alt="Inwestuj"/>
                                    <p className="button-homepage">INWESTUJ</p>
                                </HashLink>
                            </button>
                        </div>
                    </div>
                    <div className="section-homepage-image" data-aos="flip-up" data-aos-duration="1500">
                        <img src={sectionImageborder} className="homepage-imgborder" alt="" loading="eager" />
                        <img src={sectionImage} className="homepage-img" alt="Jak robić żeby zrobić?" loading="eager"/>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Homepage;